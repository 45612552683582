import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  PerfectScrollbarModule,
  PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";

import { CoreCommonModule } from "@core/common.module";
import { CoreTouchspinModule } from "@core/components/core-touchspin/core-touchspin.module";

import { NavbarComponent } from "app/layout/components/navbar/navbar.component";
import { NavbarBookmarkComponent } from "app/layout/components/navbar/navbar-bookmark/navbar-bookmark.component";
import { NavbarSearchComponent } from "app/layout/components/navbar/navbar-search/navbar-search.component";

import { NavbarNotificationComponent } from "app/layout/components/navbar/navbar-notification/navbar-notification.component";
import { ProfileService } from "app/features/profile/profile.service";
import { NavbarService } from "./navbar.service";
import { AvatarComponent } from "../avatar/avatar.component";
import { TodoService } from "app/features/todo/todo.service";
import { CalendarService } from "app/features/calendar/calendar.service";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { TourComponent } from "app/features/tour/tour.component";
import { CommonModule } from "@angular/common";
import { AvatarXLComponent } from "app/features/profile/components/avatar-xl/avatar-xl.component";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarSearchComponent,
    NavbarBookmarkComponent,
    NavbarNotificationComponent,
    AvatarComponent,
    TourComponent,
    AvatarXLComponent,
  ],
  imports: [
    SweetAlert2Module.forRoot(),
    RouterModule,
    NgbModule,
    CoreCommonModule,
    PerfectScrollbarModule,
    CoreTouchspinModule,
    CommonModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ProfileService,
    NavbarService,
    TodoService,
    CalendarService,
  ],
  exports: [NavbarComponent, AvatarComponent, TourComponent, AvatarXLComponent],
})
export class NavbarModule {}
