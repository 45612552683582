import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { CoreCommonModule } from "@core/common.module";

import { AuthLoginComponent } from "app/features/authentication/auth-login/auth-login.component";
import { AuthRegisterComponent } from "./auth-register/auth-register.component";
import { TermsModalComponent } from "./terms-modal/terms-modal.component";
import { AuthForgotPasswordComponent } from "./auth-forgot/auth-forgot.component";
import { AuthResetPasswordComponent } from "./reset-password/reset-password.component";

// routing
const routes: Routes = [
  {
    path: "login",
    component: AuthLoginComponent,
    data: { animation: "auth" },
  },
  {
    path: "register",
    component: AuthRegisterComponent,
    data: { animation: "auth" },
  },
  {
    path: "forgot-password",
    component: AuthForgotPasswordComponent,
    data: { animation: "auth" },
  },
  {
    path: "reset-password",
    component: AuthResetPasswordComponent,
    data: { animation: "auth" },
  },
];

@NgModule({
  declarations: [
    AuthLoginComponent,
    AuthRegisterComponent,
    TermsModalComponent,
    AuthForgotPasswordComponent,
    AuthResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CoreCommonModule,
  ],
  providers: [TermsModalComponent],
})
export class AuthenticationModule {}
