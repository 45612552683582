<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            width="60"
          />
        </span>
        <h2 class="brand-text p-0 m-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <app-navbar-notification></app-navbar-notification>
    <li class="nav-item d-lg-block">
      <a
        type="button"
        class="nav-link nav-link-style btn"
        (click)="toggleDarkSkin()"
      >
        <span
          [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
          class="ficon font-medium-5 feather"
        ></span>
      </a>
    </li>

    <!-- <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <i
          class="flag-icon flag-icon-{{
            languageOptions[_translateService.currentLang].flag
          }}"
        ></i
        ><span class="selected-language">{{
          languageOptions[_translateService.currentLang].title
        }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a
          *ngFor="let lang of _translateService.getLangs()"
          ngbDropdownItem
          (click)="setLanguage(lang)"
        >
          <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>
          {{ languageOptions[lang].title }}
        </a>
      </div>
    </li> -->

    <!-- <app-navbar-search></app-navbar-search> -->

    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">
            {{ currentUser?.fullname }}
          </span>
          <span class="user-status">
            <div
              *ngIf="currentClient"
              class="badge badge-pill badge-glow badge-primary d-flex align-items-center mt-50"
            >
              {{ currentClient?.title | capitalize }}
            </div>
          </span>
        </div>
        <avatar></avatar>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a
          class="d-flex align-items-center"
          ngbDropdownItem
          [routerLink]="['/profile']"
        >
          <span [data-feather]="'user'" [class]="'mr-50'"></span>

          Compte
        </a>
        <a
          class="d-flex align-items-center"
          ngbDropdownItem
          [routerLink]="['/cgu']"
        >
          <span [data-feather]="'award'" [class]="'mr-50'"></span>
          CGU
        </a>
        <a
          class="d-flex align-items-center"
          ngbDropdownItem
          (click)="tour.startTour()"
        >
          <span [data-feather]="'life-buoy'" [class]="'mr-50'"></span>
          Aide
        </a>
        <a
          class="d-flex align-items-center"
          ngbDropdownItem
          [routerLink]="['/faq']"
        >
          <span [data-feather]="'help-circle'" [class]="'mr-50'"></span>
          FAQ
        </a>
        <div class="dropdown-divider"></div>
        <!-- <a
          class="d-flex align-items-center text-danger"
          ngbDropdownItem
          [swal]="ConfirmAlert"
        >
          <span [data-feather]="'trash-2'" [class]="'mr-50'"></span>
          Supprimer
        </a>
        <div class="dropdown-divider"></div> -->
        <a class="d-flex align-items-center" ngbDropdownItem (click)="logout()">
          <span [data-feather]="'power'" [class]="'mr-50'"></span>
          Déconnexion
        </a>
      </div>
    </li>
  </ul>
</div>
<tour #tour> </tour>
<swal #ConfirmAlert (open)="deleteAccount()"></swal>
