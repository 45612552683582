import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent {
  private _menuType: string;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {
    this._menuType = "vertical-menu";
  }

  get menuType(): string {
    return this._menuType;
  }

  @Input()
  set menuType(value: string) {
    this._renderer.removeClass(this._elementRef.nativeElement, this.menuType);
    this._menuType = value;
    this._renderer.addClass(this._elementRef.nativeElement, value);
  }
}
