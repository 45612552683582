<span
  class="avatar size-{{ size }} {{
    shadow == true ? 'shadow' : ''
  }} avatar-border bg-white"
>
  <img
    *ngIf="avatar?.length > 0 && show == false"
    class="round object-fit-cover size-{{ size }} bg-light-{{
      isStatic ? (fullname | color) : (account?.fullname | color)
    }}"
    [src]="viewPhoto(avatar)"
    alt="avatar"
    [height]="size"
    [width]="size"
  />

  <img
    *ngIf="avatar?.length > 0 && show == true"
    [swal]="avatarPhoto"
    class="round object-fit-cover size-{{ size }} bg-light-{{
      isStatic ? (fullname | color) : (account?.fullname | color)
    }}"
    [src]="viewPhoto(avatar)"
    alt="avatar"
    [height]="size"
    [width]="size"
  />

  <div
    *ngIf="!avatar"
    class="size-{{ size }} bg-light-{{
      isStatic ? (fullname | color) : (account?.fullname | color)
    }} avatar-content"
  >
    {{
      isStatic
        ? (fullname | initials | uppercase)
        : (account?.fullname | initials | uppercase)
    }}
  </div>
  <span
    *ngIf="!isStatic"
    placement="bottom"
    class="avatar-status-{{ sizeStyle }} avatar-status-{{
      account?.status || 'offline'
    }}"
  >
  </span>
  <span
    *ngIf="isStatic && showStatus"
    placement="bottom"
    class="avatar-status-{{ sizeStyle }} avatar-status-{{
      status || 'offline'
    }}"
  >
  </span>
</span>

<swal
  #avatarPhoto
  [swalOptions]="{
    imageUrl: viewPhoto(avatar),
    imageWidth: '400px',
    imageHeight: 'auto',
    imageAlt: fullname,
    customClass: {
      image: 'swal-custom-image rounded',
      popup: 'swal-custom-popup bg-transparent'
    },
    width: 'auto',
    showCloseButton: false,
    showConfirmButton: false
  }"
>
</swal>
