import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { CalendarEvent } from "../../auth/models/event.model";
import { environment } from "environments/environment";
import { ApiResponse, User } from "app/auth/models";
import { Calendar } from "../../auth/models/calendar.model";
import { mapCalendar } from "app/auth/helpers/mapping";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { Apollo } from "apollo-angular";
import { CALENDAR_SUBSCRIPTION } from "app/apollo/requests/subscriptions";

@Injectable()
export class CalendarService implements Resolve<any> {
  public events: CalendarEvent[] = [];
  public calendar: Calendar[] = [];
  public currentEvent: CalendarEvent | any = {};
  public tempEvents: CalendarEvent[] = [];
  private user: User;

  public onEventChange: BehaviorSubject<any>;
  public onCurrentEventChange: BehaviorSubject<any>;
  public onCalendarChange: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient,
    private _notificationService: NotificationsService,
    private _apollo: Apollo
  ) {
    this.onEventChange = new BehaviorSubject({});
    this.onCurrentEventChange = new BehaviorSubject({});
    this.onCalendarChange = new BehaviorSubject({});

    this.user = JSON.parse(localStorage.getItem("currentUser"));
  }

  markEventsAsSeen(state) {
    this._notificationService.onNotificationsChange.subscribe((data) => {
      if (
        data?.some(
          (notification) => notification?.type?.toLowerCase() === "event"
        ) &&
        this.user?.id &&
        state.url === "/calendar"
      ) {
        this._notificationService.markEventsAsSeen(this.user?.id);
      }
    });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.markEventsAsSeen(state);
    return new Promise((resolve, reject) => {
      Promise.all([this.getEvents(), this.getCalendar()]).then((res) => {
        this.subscribeToEvents();
        resolve(res);
      }, reject);
    });
  }

  getEvents(): Promise<CalendarEvent[]> {
    if (!this.user?.token) return;
    const url = `${environment.apiUrl}/api/Calendar/GetEvents/${this.user.id}`;
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(url)
        .subscribe((response: ApiResponse<CalendarEvent[]>) => {
          this.events = response.data;
          this.tempEvents = this.events;

          this.onEventChange.next(this.events);
          resolve(this.events);
        }, reject);
    });
  }

  getCalendar(): Promise<Calendar[]> {
    const url = `${environment.apiUrl}/api/Calendar/GetCalendar`;
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(url)
        .subscribe((response: ApiResponse<Calendar[]>) => {
          if (response.status === "success")
            this.calendar = response.data.map((calendar: Calendar) =>
              mapCalendar(calendar)
            );
          this.onCalendarChange.next(this.calendar);
          resolve(this.calendar);
        }, reject);
    });
  }

  createNewEvent() {
    this.currentEvent = new CalendarEvent();
    this.onCurrentEventChange.next(this.currentEvent);
  }

  calendarUpdate(calendars: Calendar[]) {
    const calendarsChecked: Calendar[] = calendars.filter(
      (calendar: any) => calendar.checked === true
    );

    let filteredCalendar = this.tempEvents.filter((event: CalendarEvent) => {
      return calendarsChecked.some((calendar: Calendar) => {
        return calendar.id === event.calendarId;
      });
    });
    this.events = filteredCalendar;

    this.onEventChange.next(this.events);
  }

  deleteEvent(event: CalendarEvent) {
    const url = `${environment.apiUrl}/api/Calendar/DeleteEvent/${event.id}`;
    return new Promise((resolve, reject) => {
      this._httpClient.delete(url).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  addEvent(event: CalendarEvent) {
    this.currentEvent = event;
    this.onCurrentEventChange.next(event);
    this.postNewEvent();
  }

  postNewEvent() {
    const url = `${environment.apiUrl}/api/Calendar/AddEvent`;
    return new Promise((resolve, reject) => {
      this._httpClient.post(url, this.currentEvent).subscribe((response) => {
        this.currentEvent = null;
        resolve(response);
      }, reject);
    });
  }

  postUpdatedEvent(event) {
    const url = `${environment.apiUrl}/api/Calendar/UpdateEvent/${event.id}`;
    return new Promise((resolve, reject) => {
      this._httpClient.post(url, { ...event }).subscribe((response) => {
        this.currentEvent = null;
        resolve(response);
      }, reject);
    });
  }

  private subscribeToEvents() {
    return this._apollo
      .subscribe({
        query: CALENDAR_SUBSCRIPTION,
        variables: {
          userId: this.user?.id,
        },
      })
      .subscribe(({ data }: any) => {
        const updatedEvents = data?.onCalendarChange;
        this.onEventChange.next(updatedEvents);
        this.tempEvents = updatedEvents;
      });
  }
}
