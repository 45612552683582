<!-- Chat Sidebar area -->
<div class="chat-sidebar bg-white">
  <button
    class="btn sidebar-close-icon"
    (click)="toggleSidebar('chat-sidebar')"
  >
    <i data-feather="x"></i>
  </button>
  <div class="chat-fixed-search">
    <div class="d-flex align-items-center w-100">
      <div class="sidebar-profile-toggle">
        <button
          class="btn avatar avatar-border bg-light-secondary p-0"
          (click)="toggleSidebar('chat-user-sidebar')"
        >
          <avatar
            *ngIf="user"
            [avatar]="user?.avatar"
            [fullname]="user?.fullname"
            [isStatic]="true"
            [size]="42"
            [showStatus]="true"
            [status]="user?.status"
          ></avatar>
        </button>
      </div>
      <div class="input-group input-group-merge ml-1 w-100">
        <div class="input-group-prepend">
          <span class="input-group-text round"
            ><i data-feather="search" class="text-muted"></i
          ></span>
        </div>
        <input
          type="text"
          class="form-control round"
          id="chat-search"
          placeholder="Chercher une discussion"
          aria-label="Chercher..."
          aria-describedby="chat-search"
          [(ngModel)]="searchText"
        />
      </div>
    </div>
  </div>
  <div
    id="users-list"
    class="chat-user-list-wrapper list-group"
    [perfectScrollbar]
  >
    <h4 class="chat-list-title">Chats</h4>

    <ul class="chat-users-list chat-list media-list">
      <li *ngIf="!conversations?.length">
        <h6 class="mb-0">Aucun chat trouvé</h6>
      </li>
      <li
        *ngFor="
          let conversation of conversations;
          trackBy: trackByConversationId
        "
        (click)="
          openChat(
            conversation?.contact?.user?.id,
            conversation?.conversationId
          );
          toggleSidebar('chat-sidebar')
        "
        [class.active]="conversation?.isActive"
      >
        <span class="d-flex w-100">
          <avatar
            *ngIf="conversation?.contact?.user"
            [avatar]="conversation?.contact?.user?.avatar"
            [fullname]="conversation?.contact?.user?.fullname"
            [isStatic]="true"
            [size]="42"
            [showStatus]="true"
            [status]="conversation?.contact?.user?.status"
          ></avatar>
          <div class="chat-info flex-grow-1">
            <h5 class="mb-0">
              {{ conversation?.contact?.user?.fullname }}
              {{ conversation?.contact?.user?.deleted ? "(supprimé)" : "" }}
            </h5>
            <p class="card-text text-truncate">
              {{ conversation?.contact?.lastMessage || "..." }}
            </p>
          </div>
          <div class="chat-meta text-nowrap">
            <small class="float-right mb-25 chat-time">
              {{
                conversation?.lastMessageAt != "Invalid Date"
                  ? (conversation?.lastMessageAt | date : "HH:mm")
                  : ""
              }}
            </small>
            <span
              *ngIf="conversation?.contact?.unreadMessagesCount"
              class="float-right mx-25 badge badge-danger badge-pill"
            >
              {{ conversation?.contact?.unreadMessagesCount }}
            </span>
          </div>
        </span>
      </li>
    </ul>

    <h4 class="chat-list-title">Contacts</h4>

    <ul class="chat-users-list contact-list media-list">
      <li *ngIf="!(contacts | filter : searchText : 'fullname')?.length">
        <h6 class="mb-0">Aucun contact trouvé</h6>
      </li>
      <li
        *ngFor="let contact of contacts | filter : searchText : 'fullname'"
        (click)="openChat(contact.id, null); toggleSidebar('chat-sidebar')"
        [class.hide]="!(contacts | filter : searchText : 'fullname')?.length"
      >
        <div
          class="d-flex w-100"
          *ngIf="(contacts | filter : searchText : 'fullname')?.length"
        >
          <avatar
            *ngIf="contact"
            [avatar]="contact?.avatar"
            [fullname]="contact?.fullname"
            [isStatic]="true"
            [size]="42"
            [showStatus]="true"
            [status]="contact?.status"
          ></avatar>
          <div class="chat-info">
            <h5 class="mb-0">{{ contact?.fullname }}</h5>
            <p class="card-text text-truncate">
              {{ contact?.about }}
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
