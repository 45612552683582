import {
  Component,
  HostListener,
  HostBinding,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";

import { User } from "app/auth/models";
import { NavbarService } from "app/layout/components/navbar/navbar.service";

@Component({
  selector: "[core-menu-horizontal-collapsible]",
  templateUrl: "./collapsible.component.html",
})
export class CoreMenuHorizontalCollapsibleComponent
  implements OnInit, OnDestroy
{
  coreConfig: any;
  currentUser: User;
  isShow = false;

  @HostBinding("class.active")
  @HostBinding("class.open")
  @HostBinding("class.sidebar-group-active")
  public isActive = false;

  @Input()
  item: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private el: ElementRef,
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _navbarService: NavbarService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this._navbarService.onUserChange
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.currentUser = this._navbarService.currentUser;
      });

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.confirmUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
      });

    if (this.confirmUrlInChildren(this.item, this._router.url)) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  @HostListener("mouseenter")
  show(): void {
    this.isShow = true;
    this.setSubMenuProp();
  }

  @HostListener("mouseleave")
  hide(): void {
    this.isShow = false;
  }

  setSubMenuProp(): void {
    setTimeout(() => {
      let nativeElement = this.el.nativeElement,
        nativeElementChildren = this.el.nativeElement.children[1];
      if (nativeElement.classList.contains("dropdown-submenu")) {
        const innerHeight = window.innerHeight,
          dropdownTop = nativeElementChildren.getBoundingClientRect().top,
          dropdownLeft = nativeElementChildren.getBoundingClientRect().left,
          dropdownHeight = nativeElementChildren.scrollHeight,
          dropdownWidth = nativeElementChildren.scrollWidth;

        if (innerHeight - dropdownTop - dropdownHeight - 28 < 1) {
          let maxHeight = innerHeight - dropdownTop - 25;
          nativeElementChildren.setAttribute(
            "style",
            "overflow-y: auto; overflow-x: hidden; max-height : " +
              maxHeight +
              "px"
          );
        }

        if (dropdownLeft + dropdownWidth - (window.innerWidth - 16) >= 0) {
          nativeElementChildren.parentElement.classList.add("openLeft");
        }
      }
    });
  }

  confirmUrlInChildren(parent, url): boolean {
    const children = parent.children;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this.confirmUrlInChildren(child, url)) {
          return true;
        }
      }

      if (child.url === url || url.includes(child.url)) {
        return true;
      }
    }

    return false;
  }
}
