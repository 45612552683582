<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <a class="brand-logo" href="javascript:void(0);">
      <img
        src="{{ coreConfig.app.appLogoImage }}"
        alt="brand-logo"
        height="28"
      />
      <h2 class="brand-text text-primary ml-1">
        {{ coreConfig.app.appName }}
      </h2></a
    >

    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          [src]="'assets/images/pages/register-v2.svg'"
          alt="Register V2"
        />
      </div>
    </div>

    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">
          L'aventure commence ici! 🫰
        </h2>
        <p class="card-text mb-2">
          Gérer votre entreprise n'a jamais été aussi simple.
        </p>
        <!-- <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert> -->
        <form
          class="auth-register-form mt-2"
          [formGroup]="registerForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label for="register-username" class="form-label">
              Nom d'utilisateur
            </label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              placeholder="johndoe"
              aria-describedby="register-username"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
            />
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.username.errors.required">
                Nom d'utilisateur est requis
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="register-firstname" class="form-label"> Prénom </label>
            <input
              type="text"
              formControlName="firstname"
              class="form-control"
              placeholder="John"
              aria-describedby="register-firstname"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }"
            />
            <div
              *ngIf="submitted && f.firstname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.firstname.errors.required">Prénom est requis</div>
            </div>
          </div>

          <div class="form-group">
            <label for="register-lastname" class="form-label">
              Nom de famille
            </label>
            <input
              type="text"
              formControlName="lastname"
              class="form-control"
              placeholder="DOE"
              aria-describedby="register-lastname"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }"
            />
            <div
              *ngIf="submitted && f.lastname.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.lastname.errors.required">
                Nom de famille est requis
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="register-email" class="form-label"> Email </label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              placeholder="john@example.com"
              aria-describedby="register-email"
              tabindex="2"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">
                L'email doit être une adresse email valide
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="register-password" class="form-label">
              Mot de passe
            </label>

            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="register-password"
                tabindex="3"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">
                Le mot de passe est requis
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                formControlName="terms"
                type="checkbox"
                id="register-privacy-policy"
                tabindex="4"
                [ngClass]="{
                  'is-invalid error': submitted && f.terms.errors
                }"
              />
              <label
                class="custom-control-label d-flex"
                for="register-privacy-policy"
              >
                <terms-modal [termsControl]="f.terms"></terms-modal>
              </label>
            </div>
            <div
              *ngIf="submitted && f.terms.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.terms.errors }"
            >
              <div *ngIf="f.terms.errors.required">
                Vous devez accepter les termes et conditions
              </div>
            </div>
          </div>
          <button
            [disabled]="loading"
            class="btn btn-primary btn-block"
            tabindex="5"
            rippleEffect
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
            S'inscrire
          </button>
        </form>
        <p class="text-center mt-2">
          <span> Vous avez déjà un compte ?</span>
          <a routerLink="/authentication/login">
            <span>&nbsp;Se connecter à la place </span>
          </a>
        </p>
        <div class="divider my-2">
          <div class="divider-text">Sinon</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <a
            id="google-signup-button"
            href="javascript:void(0)"
            (click)="signInWithGoogle()"
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
