import { Check, User } from "../models";
import { Address } from "../models/address.model";
import { Calendar } from "../models/calendar.model";
import { Contact } from "../models/chat/contact.model";
import { EmployeeProject } from "../models/employeeProjects.model";
import { CalendarEvent } from "../models/event.model";
import { Preference } from "../models/preference.model";
import { Project } from "../models/project/project.model";
import { ProjectParticipants } from "../models/project/projectParticipants.model";
import { TimelineEvent } from "../models/timeline.model";

export function mapUser(data: any): User {
  return {
    id: data.id,
    guidId: data.guidId,
    firstname: data?.firstname,
    lastname: data?.lastname,
    username: data?.username,
    fullname: data?.fullname,
    tjm: data?.tjm,
    birthdate: new Date(data?.birthdate),
    email: data?.email,
    avatar: data?.avatar,
    about: data?.about,
    status: data?.status,
    token: data?.token,
    phone: data?.phone,
    jobTitle: data?.jobTitle,
    address: mapAddress(data?.address),
    checks: data?.checks?.map((check: any) => mapCheck(check)),
    isExternalAccount: data?.isExternalAccount,
    deleted: data?.deleted,
  };
}

export function mapContact(data: any): Contact {
  return {
    id: data.id,
    fullname: data?.fullname,
    email: data?.email,
    avatar: data?.avatar,
    about: data?.about,
    phone: data?.phone,
    status: data?.status,
    token: data?.token,
    deleted: data?.deleted,
  };
}

export function mapCheck(data: any): Check {
  return {
    id: data?.id,
    declarationDate: new Date(data?.declarationDate),
    attendanceStatus: data?.attendanceStatus,
    userId: data?.employeeId,
  };
}

export function mapAddress(data: any): Address {
  return {
    id: data?.id,
    street: data?.street,
    city: data?.city,
    state: data?.state,
    zipCode: data?.zipCode,
    country: data?.country,
  };
}

export function mapPreference(data: any): Preference {
  return {
    id: data?.id,
    language: data?.language,
    email: data?.email,
    message: data?.message,
    phone: data?.phone,
    notification: data?.notification,
  };
}

export function mapEvent(data: any): CalendarEvent {
  return {
    id: data.id,
    title: data.title,
    start: data.start,
    end: data.end,
    allDay: data.allDay,
    calendarId: data.calendarId,
    userId: data.userId,
    clientId: data.clientId,
    projectId: data.projectId,
    location: data.location,
    description: data.description,
    guestId: data.guestId,
    guest: data?.guest ? mapGuest(data.guest) : null,
  };
}

export function mapEventToTimeline(data: CalendarEvent): TimelineEvent {
  return {
    location: data.location || "",
    title: data.title || "",
    time: data.start || "",
    description: data.description || "",
    fileType: "",
    fileName: "",
    avatarUrl: data.guest?.avatar || "",
    clientName: data.guest?.fullname || "",
    type: data.calendarId,
  };
}

export function mapGuest(data: any): any {
  return {
    id: data.id,
    fullname: data.fullname,
    email: data.email,
    avatar: data.avatar,
    deleted: data.deleted,
  };
}

export function mapCalendar(calendar: any): Calendar {
  return new Calendar(
    calendar.id,
    calendar.filter,
    calendar.color,
    calendar.checked,
    calendar.selected
  );
}

export function mapEmployeeProjects(users: User[]): EmployeeProject[] {
  return users?.map((user) => {
    return {
      employeeProjectId: 0,
      employeeId: user?.id,
      projectId: 0,
      status: "accepted",
      role: null,
    };
  });
}

export function mapEmployeeProjectsForPrefill(
  employees: ProjectParticipants[],
  project: Project
): EmployeeProject[] {
  let updateEmployeeProjects = project?.employeeProjects
    ?.filter((em) =>
      employees?.some((employee) => employee?.employeeId === em.employeeId)
    )
    .map((em) => {
      return {
        employeeProjectId: em.employeeProjectId,
        employeeId: em.employeeId,
        projectId: em.projectId,
        status: em.status,
        role: em.role,
      };
    });

  let newEmployeeProjects = employees
    .filter(
      (employee) =>
        !updateEmployeeProjects?.some(
          (em) => em.employeeId === employee.employeeId
        )
    )
    .map((employee) => {
      return {
        employeeProjectId: 0,
        employeeId: employee.employeeId,
        projectId: project?.projectId,
        status: "accepted",
        role: null,
      };
    });

  return [...updateEmployeeProjects, ...newEmployeeProjects];
}
