import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    type: "item",
    title: "Accueil",
    translate: "MENU.PAGES.DASHBOARD.DASHBOARD",
    icon: "home",
    url: "dashboard",
  },
  {
    id: "career",
    type: "item",
    title: "Carrière",
    translate: "MENU.PAGES.DASHBOARD.CAREER",
    icon: "coffee",
    url: "career",
  },
  {
    id: "calendar",
    type: "item",
    title: "Calendrier",
    translate: "MENU.PAGES.CALENDAR",
    icon: "calendar-month",
    url: "calendar",
  },
  {
    id: "tasks",
    type: "item",
    title: "Tâches",
    translate: "MENU.PAGES.TASKS",
    icon: "list-check",
    url: "todo",
  },
  {
    id: "chat",
    type: "item",
    title: "Chat",
    translate: "MENU.PAGES.CHAT",
    icon: "message-dots",
    url: "chat",
  },
  {
    id: "email",
    type: "item",
    title: "Email",
    translate: "MENU.PAGES.EMAIL",
    icon: "mail",
    url: "email",
    hidden: true,
    disabled: true,
  },
  {
    id: "flowchart",
    type: "item",
    title: "Organigramme",
    translate: "MENU.PAGES.ORG_CHART",
    icon: "sitemap",
    url: "flowchart",
  },
];
