<div class="misc-wrapper">
  <a class="brand-logo" href="/">
    <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" />
    <h2 class="brand-text text-primary ml-1">
      {{ coreConfig.app.appName }}
    </h2>
  </a>
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">Page introuvable 🕵🏻‍♀️</h2>
      <p class="mb-2">Oops! 😖 La requête n'a pas pu être traitée.</p>
      <a class="btn btn-primary mb-2 btn-sm-block" routerLink="/" rippleEffect>
        Retour à l'accueil
      </a>
      <!-- <img
        class="img-fluid"
        [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/error-dark.svg'
            : 'assets/images/pages/error.svg'
        "
        alt="Error page"
      /> -->
      <img
        class="img-fluid"
        [src]="'assets/images/pages/error.svg'"
        alt="Error page"
      />
    </div>
  </div>
</div>
