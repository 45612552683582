<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
  ></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input
      class="form-control input"
      type="text"
      placeholder="Explore Vuexy..."
      tabindex="-1"
      data-search="search"
      (keyup)="searchUpdate($event)"
      [(ngModel)]="searchText"
      #openSearch
    /><button class="btn search-input-close p-0" (click)="toggleSearch()">
      <span [data-feather]="'x'"></span>
    </button>

    <!-- Search List -->
    <ul
      class="search-list search-list-main"
      [perfectScrollbar]
      [class.show]="searchText !== ''"
      #pageList
    >
      <!-- Pages -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"
          ><h6 class="section-label mt-75 mb-0">Pages</h6></a
        >
      </li>
      <ng-container
        *ngIf="
          (pages | filter : searchText : 'title').length;
          else noResultsPages
        "
      >
        <li
          class="auto-suggestion"
          *ngFor="
            let page of pages | filter : searchText : 'title' | slice : 0 : 4;
            let i = index
          "
          [ngClass]="{ current_item: i === activeIndex }"
          [class.current_item]="page.hover"
          (mouseover)="page.hover = true"
          (mouseout)="page.hover = false"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            (click)="toggleSearch()"
            (keyup.enter)="navigate(page.link)"
            [routerLink]="page.link"
            ><div class="d-flex justify-content-start align-items-center">
              <i [data-feather]="page.icon" class="mr-75"></i
              ><span>{{ page.title }}</span>
            </div></a
          >
        </li>
      </ng-container>

      <!-- No results found -->
      <ng-template #noResultsPages>
        <li class="auto-suggestion justify-content-between">
          <a
            class="d-flex align-items-center justify-content-between w-100 py-50"
          >
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Pages -->

      <!-- Files -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"
          ><h6 class="section-label mt-75 mb-0">Files</h6></a
        >
      </li>
      <ng-container
        *ngIf="
          (files | filter : searchText : 'title').length;
          else noResultsFiles
        "
      >
        <li
          class="auto-suggestion"
          *ngFor="
            let file of files | filter : searchText : 'title' | slice : 0 : 4
          "
          [ngClass]="{ current_item: i === activeIndex }"
        >
          <a
            class="d-flex align-items-center justify-content-between w-100"
            href="javascript:void(0)"
            ><div class="d-flex">
              <div class="mr-75">
                <img [src]="file.file" alt="png" height="32" />
              </div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ file.title }}</p>
                <small class="text-muted">{{ file.by }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{
              file.size
            }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsFiles>
        <li class="auto-suggestion justify-content-between">
          <a
            class="d-flex align-items-center justify-content-between w-100 py-50"
          >
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Files -->

      <!-- Members -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"
          ><h6 class="section-label mt-75 mb-0">Members</h6></a
        >
      </li>
      <ng-container
        *ngIf="
          (contacts | filter : searchText : 'title').length;
          else noResultsMembers
        "
      >
        <li
          class="auto-suggestion"
          *ngFor="
            let contact of contacts
              | filter : searchText : 'title'
              | slice : 0 : 4
          "
        >
          <a
            class="d-flex align-items-center justify-content-between py-50 w-100"
            href="javascript:void(0)"
            ><div class="d-flex align-items-center">
              <div class="avatar mr-75">
                <img
                  *ngIf="contact?.img"
                  [src]="contact?.img"
                  alt="png"
                  height="32"
                />
              </div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.title }}</p>
                <small class="text-muted">{{ contact.email }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{
              contact.date
            }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsMembers>
        <li class="auto-suggestion justify-content-between">
          <a
            class="d-flex align-items-center justify-content-between w-100 py-50"
          >
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Members -->
    </ul>
    <!--/ Search List -->
  </div>
</li>
