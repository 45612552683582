import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Apollo } from "apollo-angular";
import { USER_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { User } from "app/auth/models";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import { BehaviorSubject, Subscribable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class NavbarService implements OnDestroy {
  public currentUser: User;
  public avatar: string = null;
  public onAvatarChange: BehaviorSubject<string>;
  public onUserChange: BehaviorSubject<any>;

  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo,
    private _authService: AuthenticationService
  ) {
    this.onAvatarChange = new BehaviorSubject("");
    this.onUserChange = new BehaviorSubject<User>(null);

    this.onUserChange.subscribe((user) => {
      if (!user?.avatar) {
        this.onAvatarChange.next(null);
        this.avatar = null;
      }
    });

    this._authService?.onUserChange.subscribe((user) => {
      if (!user) {
        this.onAvatarChange.next(null);
        this.avatar = null;
        this.onUserChange.next(null);
      }
      if (!user?.avatar) {
        this.onAvatarChange.next(null);
        this.avatar = null;
      }
    });
  }

  setUserToLocalStorage(user: User): void {
    localStorage.setItem("currentUser", JSON.stringify(user));
  }

  getCurrentUser(): Subscribable<any> {
    return this._httpClient
      .get<any>(`${environment.apiUrl}/api/User/GetCurrentUser`)
      .pipe(
        map((user) => {
          this.currentUser = user?.data;
          this.onUserChange.next(this.currentUser);
          this._authService.onUserChange.next(this.currentUser);
          this.subscribeToUser(this.currentUser?.id);
          this.setUserToLocalStorage(this.currentUser);
          if (this.currentUser.avatar) {
            this.onAvatarChange.next(
              `${environment.apiUrl}/api/blob/view/${this.currentUser.avatar}`
            );
            this.avatar = `${environment.apiUrl}/api/blob/view/${this.currentUser.avatar}`;
            return user?.data;
          } else {
            this.onAvatarChange.next(null);
            this.avatar = null;
            return user?.data;
          }
        })
      );
  }

  private subscribeToUser(userId: number): void {
    this._apollo
      .subscribe({
        query: USER_SUBSCRIPTION,
        variables: { userId },
      })
      .subscribe((result: any) => {
        if (result.data.onUserUpdate) {
          this.currentUser = result.data.onUserUpdate;
          this.onUserChange.next(this.currentUser);
        }
      });
  }

  ngOnDestroy(): void {
    this.onUserChange.complete();
    this.onAvatarChange.complete();
    this.onUserChange.next(null);
    this.onAvatarChange.next(null);
    this.currentUser = null;
  }
}
