import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ChatService } from "../../chat.service";
import { Conversation } from "app/auth/models/chat/conversation.model";
import { Contact } from "app/auth/models/chat/contact.model";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

@Component({
  selector: "app-chat-sidebar",
  templateUrl: "./chat-sidebar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class ChatSidebarComponent implements OnInit {
  private currentChat: Conversation;
  public contacts: Contact[];
  public searchText: string;
  public conversations: Conversation[];
  public user: Contact;
  leftUser: Contact;

  constructor(
    private chatService: ChatService,
    private _coreSidebarService: CoreSidebarService
  ) {}

  ngOnInit(): void {
    this.chatService.contacts.subscribe(
      (contacts) => (this.contacts = contacts)
    );

    this.chatService.selectedChatUser.subscribe((contact: any) => {
      if (contact) {
        this.leftUser = contact;
      }
    });

    this.chatService.selectedChat.subscribe((conversation) => {
      this.currentChat = conversation;
      if (
        !this.conversations?.some(
          (c) => c.conversationId === conversation.conversationId
        ) &&
        conversation?.messages?.length === 0
      ) {
        this.conversations = this.conversations?.map((conversation) => {
          conversation["isActive"] = false;
          return conversation;
        });
      } else {
        this.conversations = this.conversations?.map((conversation) => {
          let isActive = this.isConversationActive(conversation);

          return {
            conversationId: conversation?.conversationId,
            createdAt: conversation?.createdAt,
            isGroup: conversation?.isGroup,
            lastMessageAt: new Date(
              this.formatDate(conversation?.lastMessageAt)
            ),
            messages: conversation?.messages,
            participants: conversation?.participants,
            ["isActive"]: isActive,
            ["contact"]: this.filterCurrentParticipant(conversation),
          };
        });
      }
    });

    this.chatService.currentUser.subscribe((user) => (this.user = user));

    this.chatService.conversations.subscribe((conversations) => {
      this.conversations = conversations.map((conversation) => {
        let isActive = this.isConversationActive(conversation);

        return {
          conversationId: conversation.conversationId,
          createdAt: conversation.createdAt,
          isGroup: conversation.isGroup,
          lastMessageAt: new Date(this.formatDate(conversation.lastMessageAt)),
          messages: conversation.messages,
          participants: conversation.participants,
          ["isActive"]: isActive,
          ["contact"]: this.filterCurrentParticipant(conversation),
        };
      });
    });
  }

  formatDate(dateString: any): string {
    const isoRegex =
      /([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}).([0-9]{3})Z/;
    const customRegex =
      /([0-9]{2})\/([0-9]{2})\/([0-9]{4}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/;

    const dateStr = dateString?.toString();

    if (dateStr.match(isoRegex)) return dateStr;

    if (dateStr.match(customRegex)) {
      const [datePart, timePart] = dateStr.split(" ");
      const [day, month, year] = datePart.split("/").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);

      const newDate = new Date(year, month - 1, day, hours, minutes, seconds);
      return newDate.toISOString();
    }

    const parsedDate = new Date(dateStr);

    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toISOString();
    }

    throw new Error(`Invalid date format: ${dateString}`);
  }

  trackByConversationId(index: number, conversation: Conversation): number {
    return conversation.conversationId;
  }

  toggleSidebar(name) {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  openChat(contactId: number, conversationId: number, index: number) {
    this.chatService.selectConversation(contactId, conversationId);
  }

  filterCurrentParticipant = (conversation: Conversation) =>
    conversation?.participants.find(
      (participant) => participant.userId !== this.user?.id
    );

  isConversationActive(conversation: Conversation): boolean {
    // check if conversationId is null so fetch if from participants if one of participant is equal to leftUser
    if (conversation.conversationId === null) {
      return conversation.participants.some(
        (participant) => participant.userId === this.leftUser?.id
      );
    } else {
      return conversation.conversationId === this.currentChat?.conversationId;
    }
  }
}
