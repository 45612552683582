<div class="active-user-profile-sidebar">
  <header class="user-profile-header">
    <button
      class="btn close-icon p-0"
      (click)="toggleSidebar('chat-active-sidebar')"
    >
      <i data-feather="x"></i>
    </button>
    <div class="header-profile-sidebar">
      <div>
        <avatar
          *ngIf="chatUser"
          [show]="true"
          [avatar]="chatUser?.avatar"
          [fullname]="chatUser?.fullname"
          [isStatic]="true"
          [size]="70"
          [status]="chatUser?.status"
          [sizeStyle]="'lg'"
          [showStatus]="true"
        ></avatar>
      </div>
      <h4 class="chat-user-name">{{ chatUser?.fullname }}</h4>
      <!-- <span class="user-post">{{ chatUser?.role }}</span> -->
    </div>
  </header>
  <div class="user-profile-sidebar-area" [perfectScrollbar]>
    <h6 class="section-label mb-1">A propos</h6>
    <p>{{ chatUser?.about || "..." }}</p>
    <div class="personal-info">
      <h6 class="section-label mb-1 mt-3">information Personnelle</h6>
      <ul *ngIf="!chatUser?.deleted" class="list-unstyled">
        <li class="mb-1">
          <i data-feather="mail" class="font-medium-2 mr-50"></i>
          <span class="align-middle">{{ chatUser?.email }}</span>
        </li>
        <li class="mb-1">
          <i data-feather="phone-call" class="font-medium-2 mr-50"></i>
          <span class="align-middle">{{ chatUser?.phone }}</span>
        </li>
        <!-- <li>
          <i data-feather="clock" class="font-medium-2 mr-50"></i>
          <span class="align-middle">Mon - Fri 10AM - 8PM</span>
        </li> -->
      </ul>
    </div>
    <!-- <div class="more-options more-options-overlay">
      <h6 class="section-label mb-1 mt-3">Options</h6>
      <ul class="list-unstyled">
        <li class="cursor-pointer mb-1">
          <i data-feather="tag" class="font-medium-2 mr-50"></i>
          <span class="align-middle">Add Tag</span>
        </li>
        <li class="cursor-pointer mb-1 disabled">
          <i data-feather="star" class="font-medium-2 mr-50"></i>
          <span class="align-middle">Contact Important</span>
        </li>
        <li class="cursor-pointer mb-1 disabled">
          <i data-feather="image" class="font-medium-2 mr-50"></i>
          <span class="align-middle"> Images partagées </span>
        </li>
        <li class="cursor-pointer mb-1">
          <i data-feather="trash" class="font-medium-2 mr-50"></i>
          <span class="align-middle">Delete Contact</span>
        </li>
        <li class="cursor-pointer disabled">
          <i data-feather="slash" class="font-medium-2 mr-50"></i>
          <span class="align-middle">Bloquer le Contact</span>
        </li>
      </ul>
    </div> -->
  </div>
</div>
