import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "../service";

@Injectable()
export class EventInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _toastrService: ToastrService,
    private _authenticationService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (event?.body?.method !== "GET" && event?.body?.method) {
            this.toastrMessage(
              event?.body?.message,
              event?.body?.title,
              event?.body?.status
            );
          }
          return event.body;
        }
      }),
      catchError((error) => {
        this._authenticationService.logout();
        this._router.navigate(["/"]);
        return throwError(error);
      })
    );
  }

  toastrMessage(message: string, title: string, type: string) {
    const params = {
      progressBar: true,
      enableHtml: true,
      toastClass: "toast ngx-toastr",
      closeButton: true,
    };

    switch (type) {
      case "success":
        this._toastrService.success(message, title, params);
        break;
      case "error":
        this._toastrService.error(message, title, params);
        break;
      case "info":
        this._toastrService.info(message, title, params);
        break;
      case "warning":
        this._toastrService.warning(message, title, params);
        break;
      default:
        this._toastrService.show(message, title, params);
        break;
    }
  }
}
