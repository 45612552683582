<div class="modal-dialog sidebar-lg">
  <div class="modal-content p-0">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="toggleEventSidebar()"
    >
      ×
    </button>
    <div class="modal-header mb-1">
      <h5 class="modal-title">
        {{ isDataEmpty === true ? "Ajouter de l'" : "Mise à jour de l'" }}
        événement
      </h5>
    </div>
    <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
      <form
        class="event-form needs-validation"
        [formGroup]="eventForm"
        (ngSubmit)="(eventForm.valid)"
      >
        <div class="form-group">
          <label for="title" class="form-label"> Titre de l'événement </label>
          <input
            type="text"
            class="form-control"
            id="title"
            name="title"
            formControlName="title"
            placeholder="Entrer le titre de l'événement"
            required
            [ngClass]="{
              'is-invalid': submitted && eventForm.controls?.title?.errors
            }"
          />
          <small
            class="form-text text-danger"
            *ngIf="submitted && eventForm.controls.title?.errors?.required"
          >
            Ce champ est requis!
          </small>
        </div>
        <div class="form-group">
          <label for="select-label" class="form-label">Label</label>
          <ng-select
            [items]="calendarList"
            name="selectlabel"
            formControlName="calendarId"
            bindLabel="filter"
            bindValue="id"
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <span
                class="bullet bullet-sm mr-50 bullet-{{ item.color }}"
              ></span>
              {{ item.filter }}
            </ng-template>
          </ng-select>

          <small
            class="form-text text-danger"
            *ngIf="submitted && eventForm.controls.calendarId?.errors?.required"
          >
            This field is required!
          </small>
        </div>
        <div class="form-group position-relative">
          <label for="start-date" class="form-label"> Date de début </label>
          <ng2-flatpickr
            [config]="startDateOptions"
            name="start"
            [setDate]="event.start"
            formControlName="start"
            #startDatePicker
            [ngClass]="{
              'is-invalid': submitted && eventForm.controls?.start?.errors
            }"
          ></ng2-flatpickr>
          <small
            class="form-text text-danger"
            *ngIf="submitted && eventForm.controls.start?.errors?.required"
          >
            Ce champ est requis!
          </small>
        </div>
        <div class="form-group position-relative">
          <label for="end-date" class="form-label"> Date de fin </label>
          <ng2-flatpickr
            [config]="endDateOptions"
            name="endDate"
            [setDate]="event.end"
            formControlName="end"
            #endDatePicker
          ></ng2-flatpickr>
        </div>
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input allDay-switch"
              id="customSwitch3"
              [checked]="event.allDay"
              formControlName="allDay"
              name="allDay"
            />
            <label class="custom-control-label" for="customSwitch3">
              Toute la journée
            </label>
          </div>
        </div>
        <!-- Client -->
        <div class="form-group">
          <label for="task-assigned" class="form-label d-block">Client</label>
          <ng-select
            formControlName="clientId"
            [items]="selectClients"
            placeholder="Sélectionnez un client"
            (change)="getProjects($event)"
            bindValue="id"
            bindLabel="title"
          ></ng-select>
        </div>

        <!-- Projet -->
        <div class="form-group" *ngIf="eventForm.controls.clientId.value">
          <label for="task-project" class="form-label d-block">Projet</label>
          <ng-select
            formControlName="projectId"
            [items]="selectProjects"
            placeholder="Sélectionnez un projet"
            bindValue="projectId"
            bindLabel="projectName"
          >
          </ng-select>
        </div>
        <div class="form-group select2-primary">
          <label for="event-guests" class="form-label">
            Ajouter des invités
          </label>
          <ng-select
            [items]="selectGuest"
            formControlName="guestId"
            bindValue="id"
            name="selectGuest"
          >
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <div class="media align-items-center">
                <avatar
                  class="mr-50"
                  [shadow]="false"
                  [avatar]="item?.avatar"
                  [size]="26"
                  [isStatic]="true"
                  [fullname]="item?.fullname"
                ></avatar>

                <div class="media-body">
                  <p class="mb-0">{{ item?.fullname }}</p>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group">
          <label for="event-location" class="form-label"> Localisation </label>
          <input
            type="text"
            class="form-control"
            formControlName="location"
            id="event-location"
            placeholder="Entrer la localisation de l'événement"
            name="location"
          />
        </div>
        <div class="form-group">
          <label class="form-label">Description</label>
          <textarea
            name="event-description-editor"
            id="event-description-editor"
            formControlName="description"
            placeholder="Entrer la description de l'événement"
            class="form-control"
            name="description"
          ></textarea>
        </div>
        <div class="form-group d-flex">
          <button
            type="submit"
            class="btn btn-primary add-event-btn mr-1"
            *ngIf="isDataEmpty !== false; else addEventRef"
            (click)="addEvent(eventForm)"
            rippleEffect
          >
            Ajouter
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-cancel"
            data-dismiss="modal"
            (click)="toggleEventSidebar()"
            *ngIf="isDataEmpty !== false; else deleteEventRef"
            rippleEffect
          >
            Annuler
          </button>
          <ng-template #addEventRef>
            <button
              type="submit"
              class="btn btn-primary update-event-btn mr-1"
              (click)="updateEvent(eventForm)"
              rippleEffect
            >
              Mettre à jour
            </button>
          </ng-template>
          <ng-template #deleteEventRef>
            <button
              class="btn btn-outline-danger btn-delete-event"
              (click)="deleteEvent()"
              rippleEffect
            >
              Supprimer
            </button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>
