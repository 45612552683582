import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "color",
})
export class ColorPipe implements PipeTransform {
  private colors = ["primary", "success", "danger", "warning", "info"];

  transform(fullname: string): string {
    return this.getColorFromFullname(fullname);
  }

  private getColorFromFullname(fullname: string): string {
    let hash = 0;
    for (let i = 0; i < fullname?.length; i++) {
      hash = fullname.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % this.colors.length;
    return this.colors[index];
  }
}
