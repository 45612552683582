import { NgModule } from "@angular/core";

import { FeatherIconDirective } from "@core/directives/core-feather-icons/core-feather-icons";
import { RippleEffectDirective } from "@core/directives/core-ripple-effect/core-ripple-effect.directive";
import { TextTruncateDirective } from "./core-text-truncate/core-text-truncate";

@NgModule({
  declarations: [
    RippleEffectDirective,
    FeatherIconDirective,
    TextTruncateDirective,
  ],
  exports: [RippleEffectDirective, FeatherIconDirective, TextTruncateDirective],
})
export class CoreDirectivesModule {}
