import { Inject, Injectable, InjectionToken } from "@angular/core";
import { ResolveEnd, Router } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

import * as _ from "lodash";

export const CORE_CUSTOM_CONFIG = new InjectionToken("coreCustomConfig");

@Injectable({
  providedIn: "root",
})
export class CoreConfigService {
  public localConfig: any;
  private readonly _defaultConfig: any;
  private _configSubject: BehaviorSubject<any>;

  constructor(
    private _router: Router,
    @Inject(CORE_CUSTOM_CONFIG) private _config
  ) {
    if (_config.layout.enableLocalStorage) {
      this.localConfig = JSON.parse(localStorage.getItem("config"));
    } else {
      localStorage.removeItem("config");
    }

    this._defaultConfig = this.localConfig ? this.localConfig : _config;

    this._initConfig();
  }

  set config(data) {
    let config;

    if (this.localConfig) {
      config = this.localConfig;
    } else {
      config = this._configSubject.getValue();
    }

    config = _.merge({}, config, data);

    if (config.layout.enableLocalStorage) {
      localStorage.setItem("config", JSON.stringify(config));
    }

    this._configSubject.next(config);
  }

  get config(): any | Observable<any> {
    return this._configSubject.asObservable();
  }

  get defaultConfig(): any {
    return this._defaultConfig;
  }

  private _initConfig(): void {
    this._configSubject = new BehaviorSubject(_.cloneDeep(this._defaultConfig));

    this._router.events
      .pipe(filter((event) => event instanceof ResolveEnd))
      .subscribe(() => {
        this.localConfig = JSON.parse(localStorage.getItem("config"));

        let localDefault = this.localConfig
          ? this.localConfig
          : this._defaultConfig;
        if (
          !_.isEqual(this._configSubject.getValue().layout, localDefault.layout)
        ) {
          const config = _.cloneDeep(this._configSubject.getValue());

          config.layout = _.cloneDeep(localDefault.layout);

          this._configSubject.next(config);
        }
      });
  }
  setConfig(data, param = { emitEvent: true }): void {
    let config;

    this.localConfig = JSON.parse(localStorage.getItem("config"));
    if (this.localConfig) {
      config = this.localConfig;
    } else {
      config = this._configSubject.getValue();
    }

    config = _.merge({}, config, data);

    if (config.layout.enableLocalStorage) {
      localStorage.setItem("config", JSON.stringify(config));
    }

    if (param.emitEvent === true) {
      this._configSubject.next(config);
    }
  }

  getConfig(): Observable<any> {
    return this._configSubject.asObservable();
  }

  resetConfig(): void {
    this._configSubject.next(_.cloneDeep(this._defaultConfig));
  }
}
