<div class="body-content-overlay"></div>
<section class="chat-app-window">
  <div class="start-chat-area" [ngClass]="{ 'd-none': activeChat === true }">
    <div
      class="mb-1 start-chat-icon btn"
      (click)="toggleSidebar('chat-sidebar')"
    >
      <i data-feather="message-square"></i>
    </div>
    <h4
      class="sidebar-toggle start-chat-text"
      (click)="toggleSidebar('chat-sidebar')"
    >
      Démarrez une conversation
    </h4>
  </div>

  <div
    class="active-chat"
    [ngClass]="{ 'd-none': activeChat !== true }"
    *ngIf="activeChat === true"
  >
    <div class="chat-navbar">
      <header class="chat-header">
        <div class="d-flex align-items-center">
          <button
            class="btn sidebar-toggle d-block d-lg-none mr-1 p-0"
            (click)="toggleSidebar('chat-sidebar')"
          >
            <i data-feather="menu" class="font-medium-5"></i>
          </button>
          <button
            class="btn avatar avatar-border user-profile-toggle bg-transparent m-0 mr-1 p-0"
            (click)="toggleSidebar('chat-active-sidebar')"
          >
            <avatar
              [avatar]="leftUser?.avatar"
              [fullname]="leftUser?.fullname"
              [isStatic]="true"
              [status]="leftUser?.status"
              [showStatus]="true"
              [size]="36"
            ></avatar>
          </button>
          <div class="d-block">
            <h6 class="mb-0">{{ leftUser?.fullname }}</h6>
            <span class="text-muted">{{
              leftUser?.deleted ? "Utilisateur supprimé" : leftUser?.about
            }}</span>
          </div>
        </div>
      </header>
    </div>

    <div
      (click)="
        toggled = false;
        markConversationAsSeen(conversation?.conversationId, rightUser?.id)
      "
      class="user-chats"
      [perfectScrollbar]
      #scrollMe
      [scrollTop]="scrolltop"
    >
      <div class="chats">
        <div *ngFor="let item of conversation?.messages; trackByMessageId">
          <div
            class="chat"
            *ngIf="item?.content?.length > 0"
            [ngClass]="{
              'chat-left': item?.senderId !== rightUser?.id
            }"
          >
            <div class="chat-body">
              <div class="chat-content break-word">
                <p>{{ item?.content }}</p>
                <span
                  class="d-flex align-items-center justify-content-{{
                    item?.senderId !== rightUser?.id ? 'start' : 'end'
                  }}"
                >
                  <p
                    class="chat-time-content d-flex align-items-center p-0 m-0"
                  >
                    <span *ngIf="!(item?.senderId !== rightUser?.id)">{{
                      item?.sentAt | date : "shortTime"
                    }}</span>
                    <i
                      [data-feather]="item?.isRead ? 'check-circle' : 'check'"
                      class="{{ item?.isRead ? 'text-success' : '' }} m{{
                        item.senderId !== rightUser?.id ? 'r' : 'l'
                      }}-25"
                    ></i>
                    <span *ngIf="item?.senderId !== rightUser?.id">{{
                      item?.sentAt | date : "shortTime"
                    }}</span>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form class="chat-app-form" action="javascript:void(0);">
      <div class="input-group input-group-merge mr-1 form-send-message">
        <div class="input-group-prepend">
          <a
            [disabled]="leftUser?.deleted"
            (click)="leftUser?.deleted == false ? (toggled = !toggled) : null"
            class="speech-to-text {{
              leftUser?.deleted ? 'text-secondary bg-light-secondary' : ''
            }} input-group-text"
          >
            <i data-feather="smile" class="cursor-pointer"></i>
          </a>
          <div class="emoji-mart-container" *ngIf="toggled">
            <emoji-mart
              set="{{ set }}"
              (emojiSelect)="handleSelection($event)"
              title="Couleur de l'emoji"
              locale="fr"
              theme="dark"
            ></emoji-mart>
          </div>
        </div>

        <input
          #messageInput
          [disabled]="leftUser?.deleted"
          (click)="
            markConversationAsSeen(conversation?.conversationId, rightUser?.id)
          "
          type="text"
          class="form-control message"
          placeholder="Ecrire un message"
          [(ngModel)]="chatMessage"
          name="chat-message"
          (keydown.enter)="updateChat()"
        />
        <!-- <div class="input-group-append">
          <span class="input-group-text">
            <label for="attach-doc" class="attachment-icon mb-0">
              <i
                data-feather="image"
                class="cursor-pointer lighten-2 text-secondary"
              ></i>
              <input type="file" id="attach-doc" hidden /> </label
          ></span>
        </div> -->
      </div>
      <button
        [disabled]="leftUser?.deleted"
        type="button"
        class="btn btn-primary send"
        (click)="updateChat()"
        rippleEffect
      >
        <i data-feather="send" class="d-lg-none"></i>
        <span class="d-none d-lg-block">Envoyer</span>
      </button>
    </form>
  </div>
</section>
