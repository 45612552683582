import { Component, OnInit, ViewEncapsulation } from "@angular/core";

import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

import { CalendarService } from "../../calendar.service";

@Component({
  selector: "app-calendar-main-sidebar",
  templateUrl: "./calendar-main-sidebar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CalendarMainSidebarComponent implements OnInit {
  public calendarRef = [];
  public tempRef = [];
  public checkAll = true;

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: CalendarService
  ) {}

  toggleEventSidebar() {
    this._coreSidebarService
      .getSidebarRegistry("calendar-event-sidebar")
      .toggleOpen();
  }

  AddEvent() {
    this.toggleEventSidebar();
    this._coreSidebarService
      .getSidebarRegistry("calendar-main-sidebar")
      .toggleOpen();
    this._calendarService.createNewEvent();
  }

  allChecked() {
    return this.calendarRef.every((v) => v.checked === true);
  }

  checkboxChange(event, id) {
    const index = this.calendarRef.findIndex((r) => {
      if (r.id === id) {
        return id;
      }
    });
    this.calendarRef[index].checked = event.target.checked;
    this._calendarService.calendarUpdate(this.calendarRef);
    this.checkAll = this.allChecked();
  }

  toggleCheckboxAll(event) {
    this.checkAll = event.target.checked;
    if (this.checkAll) {
      this.calendarRef.map((res) => {
        res.checked = true;
      });
    } else {
      this.calendarRef.map((res) => {
        res.checked = false;
      });
    }
    this._calendarService.calendarUpdate(this.calendarRef);
  }

  ngOnInit(): void {
    this._calendarService.onCalendarChange.subscribe((res) => {
      this.calendarRef = res;
    });
  }
}
