import { Guest } from "./guest.model";

export class CalendarEvent {
  id = 0;
  title: string;
  start: string;
  end: string | Date;
  allDay: boolean = false;
  calendarId: number;
  clientId: number;
  projectId: number;
  userId: number;
  location: string;
  description: string;
  guestId: number;
  guest?: Guest;
}
