import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { NOTIFICATION_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { User } from "app/auth/models";
import { Notifications } from "app/auth/models/notification.model";
import { environment } from "environments/environment";

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  public apiData: Notifications[] = [];
  public user: BehaviorSubject<User>;
  public onNotificationsChange: BehaviorSubject<any>;

  constructor(private _httpClient: HttpClient, private apollo: Apollo) {
    this.onNotificationsChange = new BehaviorSubject(null);
    this.user = new BehaviorSubject<any>(null);
  }

  updateTabTitle() {
    const unreadCount = this.apiData?.length;
    const baseTitle = "My Office";

    if (unreadCount > 0) {
      document.title = `${baseTitle} (${unreadCount})`;
    } else {
      document.title = baseTitle;
    }
  }

  getNotifications(userId: number) {
    this._httpClient
      .get(
        `${environment.apiUrl}/api/Notification/GetUnSeenNotifications/${userId}`
      )
      .subscribe((data: Notifications[]) => {
        this.apiData = data;
        this.updateTabTitle();
        this.onNotificationsChange.next(data);
      });
  }

  public subscribeToNotification(userId: number): void {
    this.apollo
      .subscribe({
        query: NOTIFICATION_SUBSCRIPTION,
        variables: { userId },
      })
      .subscribe((result: any) => {
        const apiData = result?.data?.onNotificationChangeReceived;
        this.apiData = apiData;

        this.updateTabTitle();
        this.onNotificationsChange.next(apiData);
      });
  }

  markNotificationAsSeen(userId: number) {
    this._httpClient
      .put(
        `${environment.apiUrl}/api/Notification/MarkNotificationsAsSeen/${userId}`,
        {}
      )
      .subscribe(() => {});
  }

  markEventsAsSeen(userId: number): void {
    this._httpClient
      .get(`${environment.apiUrl}/api/Notification/MarkEventsAsSeen/${userId}`)
      .subscribe();
  }

  markTodosAsSeen(userId: number): void {
    this._httpClient
      .get(`${environment.apiUrl}/api/Notification/MarkTodosAsSeen/${userId}`)
      .subscribe();
  }

  markConversationAsSeen(conversationId: number, userId: number): void {
    this.apiData?.some((notification) => {
      if (
        notification?.type?.toLowerCase() == "message" &&
        userId &&
        conversationId
      ) {
        setTimeout(() => {
          this._httpClient
            .get(
              `${environment.apiUrl}/api/Notification/MarkConversationAsSeen/${conversationId}/${userId}`,
              {}
            )
            .subscribe(() => {});
        }, 200);
      }
    });
  }
}
