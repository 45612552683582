import { Project } from "../project/project.model";

export class Client {
  id: number;
  contactId: number;
  contact: any | null;
  title: string;
  date: Date;
  description: string;
  projects: Project[];
}
