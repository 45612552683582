import { Component, Input, OnInit } from "@angular/core";
import { ProfileService } from "../../profile.service";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";
import { NavbarService } from "app/layout/components/navbar/navbar.service";
import { ClientService } from "app/features/career/services/client.service";

@Component({
  selector: "avatar-xl",
  templateUrl: "./avatar-xl.component.html",
  styleUrls: ["./avatar-xl.component.scss"],
})
export class AvatarXLComponent implements OnInit {
  @Input() avatar: string | null = null;
  @Input() fullname: string | null = null;
  guidId: string | null = null;
  @Input() isView: boolean = false;
  color: string = "primary";

  constructor(
    private _profileService: ProfileService,
    private _toastrService: ToastrService,
    private _navbarService: NavbarService,
    private _clientService: ClientService
  ) {}

  getColorFromFullname(fullname) {
    const colors = [
      "primary",
      "success",
      "danger",
      "warning",
      "info",
      "secondary",
    ];
    let hash = 0;
    for (let i = 0; i < fullname?.length; i++) {
      hash = fullname?.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  }

  ngOnInit() {
    this._navbarService.onUserChange.subscribe((response) => {
      if (response?.avatar) {
        this.avatar = `${environment.apiUrl}/api/blob/view/${response.avatar}`;
      }
      this.fullname = response?.fullname;
      this.guidId = response?.guidId;
      this.color = this.getColorFromFullname(this.fullname);
    });

    this._clientService.currentUser.subscribe((response) => {
      if (response?.avatar) {
        this.avatar = `${environment.apiUrl}/api/blob/view/${response.avatar}`;
      }
      this.fullname = response?.fullname;
      this.guidId = response?.guidId;
      this.color = this.getColorFromFullname(this.fullname);
    });
  }

  toastrMessage = (message: string, title: string, type: string) => {
    this._toastrService[type](title, message, {
      toastClass: "toast ngx-toastr",
      closeButton: true,
    });
  };

  uploadImage(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();

    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg"
    ) {
      this.toastrMessage(
        "Image",
        "Le type de fichier n'est pas autorisé",
        "error"
      );
      return;
    }
    if (file.size > 2097152) {
      this.toastrMessage(
        "Image",
        "La taille de l'image est trop grande",
        "error"
      );
      return;
    }

    let type = file.type.split("/")[1];
    let createFileName = `${this.guidId}.${type}`;
    formData.append("file", file, createFileName);
    this._profileService.updateUserPhoto(formData).then(() => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.avatar = e.target.result;
        this._navbarService.onAvatarChange.next(this.avatar);
        this._navbarService.avatar = this.avatar;
      };
      reader.readAsDataURL(file);
    });
  }

  deletePhoto() {
    this._profileService.deleteUserPhoto().then(() => {
      this.avatar = null;
    });
  }
}
