import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { NavbarService } from "../navbar/navbar.service";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnInit {
  @Input() show: boolean = false;
  @Input() avatar: string | null = null;
  @Input() isStatic: boolean = false;
  @Input() showStatus: boolean = false;
  @Input() fullname: string = "";
  @Input() size: number = 40;
  @Input() status: string = null;
  @Input() sizeStyle: string = "";
  @Input() shadow: boolean = true;

  account: any;
  @Input() color: string = "primary";

  constructor(
    private _navbarService: NavbarService,
    private cdRef: ChangeDetectorRef,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    this.cdRef.detectChanges();
    if (!this.isStatic) {
      this._navbarService.onUserChange.subscribe((response) => {
        this.account = response;
      });

      this._navbarService.onAvatarChange.subscribe((response) => {
        if (response) {
          if (response.includes("http")) {
            this.avatar = response;
          } else if (response.includes("data:image")) {
            this.avatar = response;
          } else {
            this._http
              .get(`${environment.apiUrl}/api/blob/view/${response}`, {
                responseType: "blob",
              })
              .subscribe((blob: Blob) => {
                this.avatar = URL.createObjectURL(blob);
              });
          }
        } else {
          this.avatar = null;
        }
      });
    }
  }

  viewPhoto(photo: string) {
    if (photo?.includes("http")) {
      return photo;
    } else if (photo?.includes("data:image")) {
      return photo;
    } else {
      return `${environment.apiUrl}/api/blob/view/${photo}`;
    }
  }
}
