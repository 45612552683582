import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ChatService } from "../chat.service";
import { Conversation } from "app/auth/models/chat/conversation.model";
import { Contact } from "app/auth/models/chat/contact.model";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { Message } from "app/auth/models/chat/message.model";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";

@Component({
  selector: "app-chat-content",
  templateUrl: "./chat-content.component.html",
  styleUrls: ["./chat-content.component.scss"],
})
export class ChatContentComponent implements OnInit {
  @ViewChild("scrollMe") scrollMe: ElementRef;
  scrolltop: number = null;
  @ViewChild("messageInput") messageInput!: ElementRef;

  public activeChat: boolean;
  public conversation: Conversation;
  public leftUser: Contact;
  public rightUser: Contact;
  public chatMessage: string = "";
  toggled: boolean = false;
  sets = [
    "native",
    "google",
    "twitter",
    "facebook",
    "emojione",
    "apple",
    "messenger",
  ];
  set = "twitter";

  constructor(
    private chatService: ChatService,
    private coreSidebarService: CoreSidebarService,
    private _notificationService: NotificationsService
  ) {}

  ngOnInit(): void {
    this.chatService.selectedChatUser.subscribe((contact: any) => {
      if (contact) {
        this.leftUser = contact;
      }
    });
    this.chatService.currentUser.subscribe((user) => (this.rightUser = user));

    this.chatService.isChatOpen.subscribe((isOpen) => {
      this.activeChat = isOpen;

      this.chatMessage = "";
      setTimeout(() => {
        this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
        this.messageInput?.nativeElement?.focus();
      }, 0);
    });

    this.chatService.selectedChat.subscribe((conversation) => {
      this.conversation = {
        conversationId: conversation?.conversationId,
        createdAt: conversation?.createdAt,
        isGroup: conversation?.isGroup,
        lastMessageAt: new Date(conversation?.lastMessageAt),
        messages: conversation?.messages?.map((message) => {
          let sentAtMinus1hour = new Date(message?.sentAt);
          sentAtMinus1hour.setHours(sentAtMinus1hour.getHours() - 1);
          return {
            messageId: message?.messageId,
            content: message?.content,
            senderId: message?.senderId,
            conversationId: message?.conversationId,
            isRead: message?.isRead,
            sentAt: new Date(sentAtMinus1hour),
          };
        }),
        participants: conversation?.participants,
      };

      setTimeout(() => {
        this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
      }, 100);
    });
  }

  formatDate(date: Date): string {
    if (date) {
      return date.toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      });
    }
    return "";
  }

  markConversationAsSeen(conversationId: number, userId: number) {
    this._notificationService.markConversationAsSeen(conversationId, userId);
  }

  handleSelection(event) {
    this.chatMessage += event.emoji.native;
  }

  trackByMessageId(index: number, message: Message): number {
    return message?.messageId;
  }

  toggleSidebar(name: string): void {
    this.coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  addLineBreaks(message, maxChars = 60) {
    return message.replace(new RegExp(`(.{${maxChars}})`, "g"), "$1\n");
  }

  updateChat() {
    if (
      this.chatMessage.trim() !== "" &&
      this.leftUser.id &&
      !this.leftUser.deleted
    ) {
      this.chatService
        .sendMessage(this.chatMessage, this.leftUser.id)
        .subscribe((res) => {
          this.chatMessage = "";
          setTimeout(() => {
            this.scrolltop = this.scrollMe?.nativeElement?.scrollHeight;
          }, 100);
        });
    }
  }
}
