import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { CoreConfigService } from "@core/services/config.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../authentication.service";
import { ToastrService } from "ngx-toastr";

interface DecodedToken {
  sub: string;
  jti: string;
  UserId: string;
  nbf: number;
  exp: number;
  iss: string;
  aud: string;
}

interface Password {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  userGuidId: string;
  userName: string;
}

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthResetPasswordComponent implements OnInit {
  public coreConfig: any;
  public passwordTextType: boolean;
  public confPasswordTextType: boolean;
  public resetPasswordForm: UntypedFormGroup;
  public submitted = false;

  private token: string;
  private email: string;
  private guidId: string;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _authService: AuthenticationService,
    private router: Router,
    private _toastrService: ToastrService
  ) {
    this._unsubscribeAll = new Subject();

    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  private decodeToken(token: string): DecodedToken {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      return JSON.parse(window.atob(base64));
    } catch (error) {
      console.error("Erreur lors du décodage du token:", error);
      throw error;
    }
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  toggleConfPasswordTextType() {
    this.confPasswordTextType = !this.confPasswordTextType;
  }

  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      const resetData: Password = {
        currentPassword: "",
        newPassword: this.resetPasswordForm.value.newPassword,
        confirmPassword: this.resetPasswordForm.value.confirmPassword,
        userGuidId: this.guidId,
        userName: this.email,
      };

      this._authService.resetPassword(resetData).subscribe((response) => {
        if (response) {
          this.resetPasswordForm.reset();
          this.router.navigate(["/authentication/login"]);
        }
        this.submitted = false;
      });
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];

      if (this.token) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {},
          replaceUrl: true,
        });
        const decodedToken = this.decodeToken(this.token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp && currentTime > decodedToken.exp) {
          this.displayToast(
            "Le token a expiré, veuillez réessayer",
            "Erreur",
            "error",
            () => {
              this.router.navigate(["/authentication/reset-password"]);
            }
          );
        } else {
          try {
            this.email = decodedToken.sub;
            this.guidId = decodedToken.jti;
          } catch (error) {
            console.error("Token invalide");
          }
        }
      }
    });

    this.resetPasswordForm = this._formBuilder.group(
      {
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
            ),
          ],
        ],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });
  }

  displayToast(
    message: string,
    title: string,
    type: string,
    click: any = null
  ) {
    const params = {
      progressBar: true,
      enableHtml: true,
      toastClass: "toast ngx-toastr",
      closeButton: true,
      onclick: click,
      timeOut: 5000,
    };

    switch (type) {
      case "success":
        this._toastrService.success(message, title, params);
        break;
      case "error":
        this._toastrService.error(message, title, params);
        break;
      case "info":
        this._toastrService.info(message, title, params);
        break;
      case "warning":
        this._toastrService.warning(message, title, params);
        break;
      default:
        this._toastrService.show(message, title, params);
        break;
    }
  }

  private passwordMatchValidator(fg: UntypedFormGroup) {
    const newPassword = fg.get("newPassword").value;
    const confirmPassword = fg.get("confirmPassword").value;

    if (newPassword !== confirmPassword) {
      fg.get("confirmPassword").setErrors({ passwordMismatch: true });
    } else {
      fg.get("confirmPassword").setErrors(null);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
