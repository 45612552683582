import { Component, ViewEncapsulation } from "@angular/core";
import { ChatService } from "./chat.service";
import { NavbarService } from "app/layout/components/navbar/navbar.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: { class: "chat-application" },
})
export class ChatComponent {
  userLoaded = false;
  constructor(
    private _chatService: ChatService,
    private _navbarService: NavbarService
  ) {
    this._navbarService.onUserChange.subscribe((user) => {
      if (user?.token) {
        this._chatService.currentUser.next(user);
        if (this._chatService.currentUser?.value?.token) {
          this.userLoaded = true;
          this._chatService.getContacts({
            userId: this._chatService.currentUser?.value?.id,
            token: this._chatService.currentUser?.value?.token,
          });
        }
      }
    });
  }
}
