export const locale = {
  lang: "fr",
  data: {
    MENU: {
      PAGES: {
        DASHBOARD: {
          DASHBOARD: "Accueil",
          CAREER: "Carrière",
          GROUPS: "Groupes",
          CLIENT: "Client",
          PROJECT: "Projet",
          EMPLOYEE: "Employé",
        },
        CALENDAR: "Calendrier",
        TASKS: "Tâches",
        EMAIL: "Email",
        CHAT: "Chat",
        ORG_CHART: "Organigramme",
      },
    },
  },
};
