<div class="media mb-2">
  <div class="avatar avatar-xl" *ngIf="avatar">
    <div class="profile-img">
      <img
        [swal]="SweetAlertCustomImage"
        *ngIf="avatar?.length > 0"
        [src]="avatar"
        class="rounded object-fit-cover img-fluid bg-light-{{ color }} img-size"
        [alt]="'avatar ' + fullname"
      />
    </div>
  </div>

  <div class="mr-1 ml-0" *ngIf="!avatar">
    <div class="rounded p-3 bg-light-{{ color }}">
      <h2 class="m-0 text-{{ color }}">
        {{ fullname | initials }}
      </h2>
    </div>
  </div>

  <div class="media-body mt-50 ml-1" *ngIf="!isView">
    <h4>
      {{ fullname }}
    </h4>
    <div class="col-12 d-block mt-1 px-0">
      <label
        class="btn btn-sm btn-primary mb-md-50 mr-75 mb-0"
        for="change-picture"
      >
        <span class="d-none d-sm-block">
          Changer
          <i class="ml-25" data-feather="upload"></i>
        </span>
        <input
          class="form-control"
          type="file"
          id="change-picture"
          hidden
          accept="image/png, image/jpeg, image/jpg"
          (change)="uploadImage($event)"
        />
        <span class="d-block d-sm-none">
          <i class="mr-0" data-feather="edit"></i>
        </span>
      </label>
      <button
        (click)="deletePhoto()"
        *ngIf="avatar"
        class="btn btn-danger btn-sm mb-md-50 mr-75 mb-0"
      >
        <span class="d-none d-sm-block">
          Supprimer
          <i class="ml-25" data-feather="trash-2"></i>
        </span>
        <span class="d-block d-sm-none">
          <i class="mr-0" data-feather="trash-2"></i>
        </span>
      </button>
    </div>
  </div>
</div>
<swal
  #SweetAlertCustomImage
  [swalOptions]="{
    imageUrl: avatar,
    imageWidth: '400px',
    imageHeight: 'auto',
    imageAlt: fullname,
    customClass: {
      image: 'swal-custom-image rounded',
      popup: 'swal-custom-popup bg-transparent'
    },
    width: 'auto',
    showCloseButton: false,
    showConfirmButton: false
  }"
>
</swal>
