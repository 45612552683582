<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            height="28"
          />
          <h2 class="brand-text text-primary ml-1">
            {{ coreConfig.app.appName }}
          </h2>
        </a>

        <h4 class="card-title mb-1">Mot de passe oublié ? 🔒</h4>
        <p class="card-text mb-2">
          Entrez votre adresse e-mail et nous vous enverrons un lien pour
          réinitialiser votre mot de passe.
        </p>

        <form
          class="auth-forgot-password-form mt-2"
          [formGroup]="forgotPasswordForm"
        >
          <div class="form-group">
            <label for="forgot-password-email" class="form-label">Email</label>
            <input
              type="text"
              class="form-control"
              placeholder="john@example.com"
              formControlName="email"
              aria-describedby="forgot-password-email"
              tabindex="1"
              autofocus
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">L'email est requis</div>
              <div *ngIf="f.email.errors.email">
                L'email doit être une adresse email valide
              </div>
            </div>
          </div>
          <button
            type="submit"
            (click)="onSubmit()"
            class="btn btn-primary btn-block"
            tabindex="2"
            rippleEffect
          >
            Envoyer le lien
          </button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/authentication/login">
            <i data-feather="chevron-left" class="mb-25"></i>
            Retour à la connexion
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
