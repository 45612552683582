import {
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CalendarOptions, EventClickArg } from "@fullcalendar/angular";

import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CalendarService } from "./calendar.service";
import { CalendarEvent } from "../../auth/models/event.model";
import { Calendar } from "app/auth/models/calendar.model";

@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, AfterViewInit {
  public slideoutShow: boolean = false;
  public events: any[] = [];
  public event: CalendarEvent | any = {};
  private _unsubscribeAll: Subject<any>;
  public calendar: Calendar;
  public calendarOptions: CalendarOptions = {
    locale: "fr",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    events: this.events,
    initialView: "dayGridMonth",
    weekText: "Semaine",
    weekends: true,
    editable: true,
    eventResizableFromStart: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: 2,
    navLinks: true,
    eventClick: this.handleUpdateEventClick.bind(this),
    eventClassNames: this.eventClass.bind(this),
    select: this.handleDateSelect.bind(this),
    timeZone: "UTC",
    displayEventTime: false,
    eventChange: this.dragUpdateEvent.bind(this),
  };

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: CalendarService,
    private _coreConfigService: CoreConfigService
  ) {
    this._unsubscribeAll = new Subject();
  }

  dragUpdateEvent(event: any) {
    let selectEvent = this.events.find(
      (e) => e.id === parseInt(event.event._def.publicId)
    );

    selectEvent.start = event.event.start;
    selectEvent.end = event.event.end;

    this._calendarService.postUpdatedEvent(selectEvent);
  }

  handleUpdateEventClick(eventRef: EventClickArg) {
    this._coreSidebarService
      .getSidebarRegistry("calendar-event-sidebar")
      .toggleOpen();

    let selectEvent = this.events.find(
      (e) => e.id === parseInt(eventRef.event._def.publicId)
    );

    this._calendarService.onCurrentEventChange.next(selectEvent);
  }

  handleDateSelect(eventRef: any) {
    const endDate = new Date(eventRef.end);
    endDate.setDate(endDate.getDate() - 1);
    const newEvent = new CalendarEvent();
    newEvent.allDay = eventRef.allDay;
    newEvent.start = eventRef.start;
    newEvent.end = endDate;

    this._calendarService.onCurrentEventChange.next(newEvent);

    this._coreSidebarService
      .getSidebarRegistry("calendar-event-sidebar")
      .toggleOpen();
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  eventClass(s: any) {
    const calendarsColor = {
      1: "primary",
      2: "success",
      3: "danger",
      4: "warning",
      5: "info",
    };

    return `bg-light-${calendarsColor[s.event.extendedProps.calendarId]}`;
  }

  formatDate(date: string): string {
    let regex = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$");

    if (regex.test(date)) {
      return date.split("-").reverse().join("-");
    } else {
      return date?.toString();
    }
  }

  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        if (config.layout.animation === "zoomIn") {
          setTimeout(() => {
            this._calendarService.onEventChange.subscribe((res) => {
              this.events = res.map((event) => ({
                ...event,
                start: new Date(this.formatDate(event.start)),
                end: new Date(this.formatDate(event.end)),
              }));
              this.calendarOptions.events = this.events;
            });
          }, 450);
        } else {
          this._calendarService.onEventChange.subscribe((res) => {
            this.events = res.map((event) => ({
              ...event,
              start: new Date(this.formatDate(event.start)),
              end: new Date(this.formatDate(event.end)),
            }));

            this.calendarOptions.events = this.events;
          });
        }
      });

    this._calendarService.onCurrentEventChange.subscribe(
      (event: CalendarEvent) => {
        this.event = event;
      }
    );

    this._calendarService.onCalendarChange.subscribe((calendar: Calendar) => {
      this.calendar = calendar;
    });
  }

  ngAfterViewInit() {
    let _this = this;
    this.calendarOptions.customButtons = {
      sidebarToggle: {
        text: "",
        click() {
          _this.toggleSidebar("calendar-main-sidebar");
        },
      },
    };
  }
}
