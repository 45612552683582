import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CalendarService } from "../../calendar.service";
import { CalendarEvent } from "../../../../auth/models/event.model";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Calendar } from "app/auth/models/calendar.model";
import { UserService } from "app/auth/service";
import { User } from "app/auth/models";
import { environment } from "environments/environment";
import { ClientCompact } from "app/auth/models/client/clientCompact.model";
import { ProjectCompact } from "app/auth/models/project/projectCompact.model";
import { ClientService } from "app/features/career/services/client.service";
import { NavbarService } from "app/layout/components/navbar/navbar.service";
import { French } from "flatpickr/dist/l10n/fr";

@Component({
  selector: "app-calendar-event-sidebar",
  templateUrl: "./calendar-event-sidebar.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CalendarEventSidebarComponent implements OnInit {
  @ViewChild("startDatePicker") startDatePicker;
  @ViewChild("endDatePicker") endDatePicker;

  public event: CalendarEvent | any = {};
  public isDataEmpty: boolean = true;
  public submitted: boolean = false;
  public calendarList: Calendar[] = [];
  public selectGuest: User[] = [];
  public selectProjects: ProjectCompact[] = [];
  public selectClients: ClientCompact[] = [];

  flatPickerOptions = {
    altInput: true,
    mode: "single",
    dateFormat: "Z",
    altFormat: "F j, Y H:i",
    locale: French,

    altInputClass:
      "form-control flat-picker flatpickr-input invoice-edit-input",
    enableTime: true,
    time_24hr: true,
    timeFormat: "H:i",
  };

  public startDateOptions = this.flatPickerOptions;
  public endDateOptions = this.flatPickerOptions;
  public eventForm: UntypedFormGroup;

  private user: User;

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: CalendarService,
    private _formBuilder: UntypedFormBuilder,
    private _userService: UserService,
    private _navbarService: NavbarService,
    private _clientService: ClientService
  ) {}

  // Helpers
  getProjects(client: any) {
    this.selectProjects = this.selectClients.find(
      (c) => c.id === client.id
    )?.projects;

    this.eventForm.get("projectId").setValue(null);
    if (this.selectProjects?.length === 0) {
      this.eventForm.patchValue({
        projectId: null,
      });
    }
  }

  formatDate(date: string): string {
    let regex = new RegExp("^[0-9]{2}-[0-9]{2}-[0-9]{4}$");

    if (regex.test(date)) {
      return date;
    } else {
      return date?.toString();
    }
  }

  toggleEventSidebar() {
    this._coreSidebarService
      .getSidebarRegistry("calendar-event-sidebar")
      .toggleOpen();
  }

  viewPhoto(photo) {
    if (photo) return `${environment.apiUrl}/api/blob/view/${photo}`;
  }

  manageDate(datePicker: any): string {
    const date = this[
      datePicker
    ].flatpickrElement.nativeElement.children[0].value?.replace(" ", "T");
    return date;
  }

  addEvent(eventForm: UntypedFormGroup) {
    this.submitted = true;
    let newEvent = new CalendarEvent();
    if (eventForm.valid) {
      newEvent = {
        id: 0,
        userId: this.user?.id ?? 0,
        title: eventForm.value.title,
        allDay: eventForm.value.allDay ?? false,
        calendarId: eventForm.value.calendarId,
        clientId: eventForm.value.clientId,
        projectId: eventForm.value.projectId,
        location: eventForm.value.location,
        description: eventForm.value.description,
        guestId: eventForm.value.guestId,
        start: this.manageDate("startDatePicker"),
        end:
          this.manageDate("endDatePicker")?.length > 0
            ? this.manageDate("endDatePicker")
            : this.manageDate("startDatePicker"),
      };

      this._calendarService.addEvent(newEvent);
      this.submitted = false;
      this.toggleEventSidebar();
    }
  }

  updateEvent(eventForm: UntypedFormGroup) {
    this.submitted = true;
    if (eventForm.valid) {
      eventForm.value.start = this.manageDate("startDatePicker");
      eventForm.value.end = this.manageDate("endDatePicker");

      this._calendarService.postUpdatedEvent(eventForm.value);
      this.submitted = false;
      this.toggleEventSidebar();
    }
  }

  deleteEvent() {
    this._calendarService.deleteEvent(this.event);
    this.toggleEventSidebar();
  }

  // Initialisation
  initForm() {
    this.eventForm = this._formBuilder.group({
      id: [0],
      title: ["", Validators.required],
      start: ["", Validators.required],
      end: [""],
      allDay: [false],
      calendarId: ["", Validators.required],
      clientId: [""],
      projectId: [""],
      userId: [this.user?.id ?? 0],
      location: [""],
      description: [""],
      guestId: [""],
    });

    this.eventForm.reset();
  }

  prefillForm(event: CalendarEvent) {
    const endDate = new Date(event.end);
    endDate.setDate(endDate.getDate() - 1);
    this.getProjects({ id: event.clientId });
    this.eventForm.patchValue({
      id: event?.id,
      title: event?.title,
      start: event?.start,
      end: event?.end,
      allDay: event?.allDay ?? false,
      calendarId: event?.calendarId,
      clientId: event?.clientId,
      projectId: event?.projectId,
      userId: event?.userId ?? this.user.id,
      location: event?.location,
      description: event?.description,
      guestId: event?.guestId,
    });
  }

  ngOnInit(): void {
    this._navbarService.onUserChange.subscribe((user: User) => {
      this.user = user;
      this.initForm();
    });

    this._calendarService.onCalendarChange.subscribe((res: Calendar[]) => {
      this.calendarList = res;
    });

    this._clientService
      .getCompactedClients()
      .subscribe((clients: ClientCompact[]) => {
        this.selectClients = clients;
      });

    this._calendarService.onCurrentEventChange.subscribe(
      (event: CalendarEvent) => {
        this.event = event;

        if (event?.id > 0 || event?.start) {
          this.event = event;
          this.prefillForm(this.event);
          this.isDataEmpty = false;
          if (event.id === 0) {
            this.isDataEmpty = true;
          }
        } else {
          this.event = {};
          this.isDataEmpty = true;
          setTimeout(() => {
            this.startDatePicker.flatpickr.clear();
            this.endDatePicker.flatpickr.clear();
          });
        }
      }
    );

    this._userService.getAllUsers().subscribe((res) => {
      this.selectGuest = [...res.data];
      this.selectGuest.map((guest) => {
        guest["img"] = this.viewPhoto(guest.avatar);
      });
    });
  }
}
