import { Pipe, PipeTransform } from "@angular/core";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";

@Pipe({
  name: "timeLeft",
})
export class TimeLeftPipe implements PipeTransform {
  transform(value: any): string {
    const currentDate = new Date();
    const eventDate = new Date(value);

    return formatDistance(eventDate, currentDate, {
      addSuffix: true,
      locale: fr,
    });
  }
}
