export const environment = {
  production: false,
  hmr: false,
  googleClientId:
    "475974876999-370r0psbc2jkir666af8o5erm1oq376h.apps.googleusercontent.com",
  // apiUrl: "http://localhost:5047",
  // graphqlUrl: "http://localhost:5047",
  // graphqlWs: "ws://localhost:5047",
  apiUrl: "https://nabilhost.freeboxos.fr",
  graphqlUrl: "https://nabilhost.freeboxos.fr",
  graphqlWs: "wss://nabilhost.freeboxos.fr",
};
