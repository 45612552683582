export class Calendar {
  id: number;
  filter: string;
  color: string;
  checked: string;
  selected: boolean;

  constructor(
    id: number,
    filter: string,
    color: string,
    checked: string,
    selected: boolean
  ) {
    this.id = id;
    this.filter = filter;
    this.color = color;
    this.checked = checked;
    this.selected = selected;
  }
}
