import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreMenuService } from "@core/components/core-menu/core-menu.service";

@Component({
  selector: "[core-menu]",
  templateUrl: "./core-menu.component.html",
  styleUrls: ["./core-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = "vertical";

  @Input()
  menu: any;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    this._coreMenuService.OnCurrentUserChange.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;
      this.menu = this._coreMenuService.getCurrentMenu();
      this._changeDetectorRef.markForCheck();
    });
  }
}
