import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { BehaviorSubject, Observable } from "rxjs";
import { Conversation } from "app/auth/models/chat/conversation.model";
import { Contact } from "app/auth/models/chat/contact.model";
import { Participant } from "app/auth/models/chat/participant.model";
import { CONVERSATIONS_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Message } from "app/auth/models/chat/message.model";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  public contacts: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(
    []
  );
  public conversations: BehaviorSubject<Conversation[]> = new BehaviorSubject<
    Conversation[]
  >([]);
  public currentUser: BehaviorSubject<any> = new BehaviorSubject<Contact>(null);
  public isChatOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public selectedChat: BehaviorSubject<Conversation> =
    new BehaviorSubject<Conversation>(null);

  public selectedChatUser: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private _apollo: Apollo, private _httpClient: HttpClient) {}

  getContacts(variables): void {
    this._httpClient
      .get(`${environment.apiUrl}/api/Chat/GetContacts/${variables?.userId}`)
      .subscribe((data: Contact[]) => {
        this.contacts.next(data ?? []);
        this.getConversations(variables);
      });
  }

  getConversations(variables): void {
    this._httpClient
      .get(`${environment.apiUrl}/api/Chat/GetChats/${variables?.userId}`)
      .subscribe((data: Conversation[]) => {
        this.conversations.next(data ?? []);
        this.subscribeToConversations(this.currentUser.value?.id);
      });
  }

  sendMessage(content: string, receiverId: number): Observable<Message> {
    const message: Message = {
      messageId: null,
      content: content,
      senderId: this.currentUser.value.id,
      conversationId: this.selectedChat.value?.conversationId,
      sentAt: new Date(),
      isRead: false,
    };

    return this._httpClient.post<Message>(
      `${environment.apiUrl}/api/Chat/CreateOrUpdateChat/${receiverId}`,
      message
    );
  }

  markMessagesAsRead(conversationId: number) {
    let selectChatUpdate = this.conversations.value?.find(
      (c) => c?.conversationId === conversationId
    );

    let unReadMessages = selectChatUpdate?.participants?.find(
      (p) => p.userId !== this.currentUser?.value?.id
    )?.unreadMessagesCount;

    if (unReadMessages > 0) {
      this._httpClient
        .post(
          `${environment.apiUrl}/api/Chat/MarkMessagesAsRead/${conversationId}/${this.currentUser.value.id}`,
          null
        )
        .subscribe();
    }
  }

  updateContact(contact: Contact): Observable<Contact> {
    return this._httpClient.patch<Contact>(
      `${environment.apiUrl}/api/Chat/UpdateContact`,
      contact
    );
  }

  private subscribeToConversations(userId: number): void {
    this._apollo
      .subscribe({
        query: CONVERSATIONS_SUBSCRIPTION,
        variables: { userId },
      })
      .subscribe((result: any) => {
        const conversationsUpdate = result?.data?.onConversationChangeReceived;
        conversationsUpdate.forEach((conv) => {
          const lastMessageDate = new Date(conv.lastMessageAt);
          lastMessageDate.setHours(lastMessageDate.getHours() - 1);
          conv.lastMessageAt = lastMessageDate.toLocaleString("fr-FR", {
            timeZone: "Europe/Paris",
          });
        });
        this.conversations.next(conversationsUpdate);
        if (this.selectedChat.value) {
          let currentContact = this.selectedChatUser.value;
          let currentChat = conversationsUpdate.find(
            (conv) =>
              conv.conversationId === this.selectedChat.value.conversationId ||
              conv.participants.find((p) => p.userId === currentContact.id)
          );
          this.selectedChat.next(currentChat);
        }
      });
  }

  selectConversation(contactId: number, conversationId: number): void {
    let selectedConversation = this.conversations?.value?.find(
      (conv) =>
        conv?.conversationId === conversationId ||
        conv?.participants?.find((p) => p.userId === contactId)
    );

    if (selectedConversation) {
      this.markMessagesAsRead(selectedConversation?.conversationId);
      this.selectedChat.next(selectedConversation);
      const otherParticipant = selectedConversation.participants.find(
        (p) => p.userId !== this.currentUser.value.id
      );
      this.selectedChatUser.next(otherParticipant.user);
      this.isChatOpen.next(true);
    } else {
      this.createNewChat(this.contacts.value.find((c) => c.id === contactId));
      this.isChatOpen.next(true);
    }
  }

  createNewChat(contact: Contact): void {
    let part1: Participant = {
      participantId: null,
      conversationId: null,
      unreadMessagesCount: 0,
      lastMessage: null,
      joinedAt: new Date(),
      userId: this.currentUser.value.id,
      user: this.currentUser.value,
    };
    let part2: Participant = {
      participantId: null,
      conversationId: null,
      unreadMessagesCount: 0,
      lastMessage: null,
      joinedAt: new Date(),
      userId: contact?.id,
      user: contact,
    };

    this.selectedChatUser.next(contact);
    this.selectedChat.next({
      conversationId: null,
      isGroup: false,
      createdAt: new Date().toLocaleString("fr-FR", {
        timeZone: "Europe/Paris",
      }),
      lastMessageAt: new Date(),
      participants: [part2, part1],
      messages: [],
    });
    this.isChatOpen.next(true);
  }
}
