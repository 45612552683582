import { Component, OnInit, OnDestroy } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ChatService } from "../../chat.service";
import { environment } from "environments/environment";
import { NavbarService } from "app/layout/components/navbar/navbar.service";

@Component({
  selector: "app-chat-user-sidebar",
  templateUrl: "./chat-user-sidebar.component.html",
})
export class ChatUserSidebarComponent implements OnInit {
  public status: any;
  public user: any;
  public about: any;

  constructor(
    private _chatService: ChatService,
    private coreSidebarService: CoreSidebarService
  ) {}

  ngOnInit(): void {
    this._chatService.currentUser.subscribe((user) => {
      if (user) {
        this.about = user?.about ?? "";
        this.status = user?.status ?? "";
        this.user = user;
      }
    }, console.error);
  }

  viewPhoto(photo: string): string {
    if (photo) return `${environment.apiUrl}/api/blob/view/${photo}`;
  }

  toggleSidebar(name: string): void {
    this.coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  updateUserStatus(): void {
    const updatedUser = {
      ...this.user,
      status: this.status,
      about: this.about,
    };
    if (this.user && this.user.id)
      this._chatService.updateContact(updatedUser).subscribe();
  }
}
