export const locale = {
  lang: "de",
  data: {
    MENU: {
      PAGES: {
        DASHBOARD: {
          DASHBOARD: "Dashboard",
          GROUPS: "Gruppen",
          CLIENT: "Client",
          PROJECT: "Project",
          EMPLOYEE: "Employee",
        },
        CALENDAR: "Kalender",
        EMPLOYEE: {
          HOME: "Employee",
          EMAIL: "Email",
          TASKS: "Tasks",
        },
      },
    },
  },
};
