<!-- Admin user profile area -->
<div class="chat-user-sidebar">
  <header class="chat-profile-header">
    <button
      class="btn close-icon p-0"
      (click)="toggleSidebar('chat-user-sidebar')"
    >
      <i data-feather="x"></i>
    </button>
    <!-- User Information -->
    <div class="header-profile-sidebar">
      <avatar
        *ngIf="user"
        [avatar]="user?.avatar"
        [size]="70"
        [fullname]="user?.fullname"
        [status]="user?.status"
        [isStatic]="true"
        [sizeStyle]="'lg'"
        [showStatus]="true"
        [show]="true"
      ></avatar>

      <h4 class="chat-user-name">{{ user?.fullname }}</h4>
      <span class="user-post">{{ user?.role?.description }}</span>
    </div>
    <!--/ User Information -->
  </header>
  <!-- User Details start -->
  <div class="profile-sidebar-area" [perfectScrollbar]>
    <h6 class="section-label mb-1">A propos de vous</h6>
    <div class="about-user">
      <textarea
        data-length="120"
        class="form-control char-textarea"
        id="textarea-counter"
        rows="4"
        placeholder="..."
        maxlength="120"
        [(ngModel)]="about"
      >
      </textarea>
      <small class="textarea-counter-value float-left ml-1"
        ><span *ngIf="about" class="char-count">{{ about?.length ?? 0 }}</span>
        <span *ngIf="!about" class="char-count">0 </span> / 120
      </small>
      <div class="d-flex mt-50 float-right">
        <button
          (click)="updateUserStatus()"
          rippleEffect
          class="btn btn-primary btn-sm"
        >
          <i data-feather="save"></i>
        </button>
      </div>
    </div>
    <!-- To set user status -->
    <h6 class="section-label mb-1 mt-4">Statut</h6>
    <ul class="list-unstyled user-status">
      <li class="pb-1">
        <div class="custom-control custom-control-success custom-radio">
          <input
            type="radio"
            id="activeStatusRadio"
            name="userStatus"
            class="custom-control-input"
            (change)="updateUserStatus()"
            [(ngModel)]="status"
            value="online"
            checked
          />
          <label class="custom-control-label ml-25" for="activeStatusRadio"
            >Active</label
          >
        </div>
      </li>
      <li class="pb-1">
        <div class="custom-control custom-control-danger custom-radio">
          <input
            type="radio"
            id="dndStatusRadio"
            name="userStatus"
            class="custom-control-input"
            (change)="updateUserStatus()"
            [(ngModel)]="status"
            value="busy"
          />
          <label class="custom-control-label ml-25" for="dndStatusRadio">
            Ne pas déranger
          </label>
        </div>
      </li>
      <li class="pb-1">
        <div class="custom-control custom-control-warning custom-radio">
          <input
            type="radio"
            id="awayStatusRadio"
            name="userStatus"
            class="custom-control-input"
            (change)="updateUserStatus()"
            [(ngModel)]="status"
            value="away"
          />
          <label class="custom-control-label ml-25" for="awayStatusRadio">
            Parti
          </label>
        </div>
      </li>
      <li class="pb-1">
        <div class="custom-control custom-control-secondary custom-radio">
          <input
            type="radio"
            id="offlineStatusRadio"
            name="userStatus"
            class="custom-control-input"
            (change)="updateUserStatus()"
            [(ngModel)]="status"
            value="offline"
          />
          <label class="custom-control-label ml-25" for="offlineStatusRadio">
            Hors ligne
          </label>
        </div>
      </li>
    </ul>
    <!--/ To set user status -->

    <!-- User settings -->
    <!-- <h6 class="section-label mb-1 mt-2">Settings</h6>
    <ul class="list-unstyled">
      <li class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex align-items-center">
          <i data-feather="check-square" class="mr-75 font-medium-3"></i>
          <span class="align-middle">Two-step Verification</span>
        </div>
        <div class="custom-control custom-switch mr-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch1"
            [checked]="
              user?.settings?.isTwoStepAuthVerificationEnabled || false
            "
          />
          <label class="custom-control-label" for="customSwitch1"></label>
        </div>
      </li>
      <li class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex align-items-center">
          <i data-feather="bell" class="mr-75 font-medium-3"></i>
          <span class="align-middle">Notification</span>
        </div>
        <div class="custom-control custom-switch mr-0">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch2"
            [checked]="user?.settings?.isNotificationsOn || false"
          />
          <label class="custom-control-label" for="customSwitch2"></label>
        </div>
      </li>
      <li class="mb-1 d-flex align-items-center cursor-pointer">
        <i data-feather="user" class="mr-75 font-medium-3"></i>
        <span class="align-middle">Invite Friends</span>
      </li>
      <li class="d-flex align-items-center cursor-pointer">
        <i data-feather="trash" class="mr-75 font-medium-3"></i>
        <span class="align-middle">Delete Account</span>
      </li>
    </ul> -->
    <!--/ User settings -->

    <!-- Logout Button -->
    <!-- <div class="mt-3">
      <button class="btn btn-primary" rippleEffect>
        <span> Se déconnecter </span>
      </button>
    </div> -->
    <!--/ Logout Button -->
  </div>
  <!-- User Details end -->
</div>
<!--/ Admin user profile area -->
