import { InMemoryCache, HttpLink, split } from "@apollo/client/core";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { environment } from "environments/environment";

export function CreateApollo() {
  const httpLink = new HttpLink({
    uri: `${environment.graphqlUrl}/graphql`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${environment.graphqlWs}/graphql`,
    })
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return {
    link,
    cache: new InMemoryCache(),
  };
}
