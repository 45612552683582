import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";
import { ApiResponse, User } from "app/auth/models";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Password } from "../models/password.model";
import { mapUser } from "../helpers/mapping";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: "root" })
export class UserService {
  public users: User[] = [];
  public currentUser: User | any = {};
  public tempUsers: User[] = [];
  public page: number = 1;
  public pageSize: number = 10;
  public total: number = 0;

  public onUserChange: BehaviorSubject<any>;
  public onCurrentUserChange: BehaviorSubject<any>;

  public onPageChange: BehaviorSubject<{ page: number; pageSize: number }>;

  constructor(private _http: HttpClient) {
    this.onCurrentUserChange = new BehaviorSubject({});
    this.onUserChange = new BehaviorSubject({});
    this.onPageChange = new BehaviorSubject<{ page: number; pageSize: number }>(
      {
        page: 1,
        pageSize: 10,
      }
    );
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.loadUsers(this.page, this.pageSize)]).then((res) => {
        resolve(res);
      }, reject);
    });
  }

  loadUsers(page: number, pageSize: number): Promise<User[]> {
    const url = `${environment.apiUrl}/api/User/GetPagedUsers/${page}/${pageSize}`;
    return new Promise((resolve, reject) => {
      this._http.get(url).subscribe((response: ApiResponse<User[]>) => {
        if (response.status === "success") {
          this.users = response.data.map((event) => mapUser(event));
          this.tempUsers = this.users;
          this.total = response.totalCount;
          if (this.onUserChange) {
            this.onUserChange.next(this.users);
          }
        }
        resolve(this.users);
      }, reject);
    });
  }

  changePage(page: number, pageSize: number): void {
    this.page = page;
    this.pageSize = pageSize;
    this.loadUsers(page, pageSize);
    this.onPageChange.next({ page, pageSize });
  }

  createNewUser() {
    this.currentUser = {};
    if (this.onCurrentUserChange) {
      this.onCurrentUserChange.next(this.currentUser);
    }
  }

  addUser(userForm: any) {
    this.currentUser = mapUser(userForm);
    if (this.onCurrentUserChange) {
      this.onCurrentUserChange.next(this.currentUser);
    }
    this.postNewUser();
  }

  postNewUser() {
    const url = `${environment.apiUrl}/api/User/AddUser`;
    return new Promise((resolve, reject) => {
      this._http.post(url, this.currentUser).subscribe((response) => {
        this.loadUsers(this.page, this.pageSize);

        resolve(response);
      }, reject);
    });
  }

  updateUser(user: User): Observable<ApiResponse<boolean>> {
    return this._http
      .patch<ApiResponse<boolean>>(
        `${environment.apiUrl}/api/User/UpdateUser`,
        user
      )
      .pipe(map((data) => data));
  }

  updatePassword(request: Password): Observable<ApiResponse<boolean>> {
    return this._http
      .patch<ApiResponse<boolean>>(
        `${environment.apiUrl}/api/User/UpdatePassword`,
        request
      )
      .pipe(map((data) => (data?.data ? data : null)));
  }

  getAllUsers(): Observable<ApiResponse<User[]>> {
    return this._http
      .get<ApiResponse<User[]>>(`${environment.apiUrl}/api/User/GetAllUsers`)
      .pipe(
        map((dataArray) => {
          const data = dataArray.data;
          const users: User[] = data.map((user) => mapUser(user));
          return { ...dataArray, data: users };
        })
      );
  }

  getTotalWorkForceAsync(): Observable<ApiResponse<number[]>> {
    return this._http
      .get<ApiResponse<number[]>>(
        `${environment.apiUrl}/api/Employee/GetTotalWorkForce`
      )
      .pipe(map((data) => data));
  }

  getAttendanceAsync(): Observable<ApiResponse<number[]>> {
    return this._http
      .get<ApiResponse<number[]>>(
        `${environment.apiUrl}/api/Employee/GetAttendance`
      )
      .pipe(map((data) => data));
  }
}
