import { Client } from "graphql-ws";
import { Contact } from "../chat/contact.model";
import { EmployeeProject } from "../employeeProjects.model";

export class Project {
  projectId: number;
  projectName: string;
  description: string;
  status: string;
  category: string;
  startDate: Date;
  endDate: Date;
  clientId: number;
  client?: Client | null;
  managerId: number;
  manager?: Contact | null;
  employeeProjects: EmployeeProject[];
}
