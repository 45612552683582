import { NgModule } from "@angular/core";

import { FilterPipe } from "@core/pipes/filter.pipe";

import { InitialsPipe } from "@core/pipes/initials.pipe";

import { SafePipe } from "@core/pipes/safe.pipe";
import { StripHtmlPipe } from "@core/pipes/stripHtml.pipe";
import { CapitalizePipe } from "./capitalize.pipe";
import { TimeLeftPipe } from "./timeLeft.pipe";
import { ColorPipe } from "./color.pipe";
import { TimezonePipe } from "./timeZone.pipe";

@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    CapitalizePipe,
    TimeLeftPipe,
    ColorPipe,
    TimezonePipe,
  ],
  imports: [],
  exports: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    CapitalizePipe,
    TimeLeftPipe,
    ColorPipe,
    TimezonePipe,
  ],
})
export class CorePipesModule {}
