export const locale = {
  lang: "pt",
  data: {
    MENU: {
      PAGES: {
        DASHBOARD: {
          DASHBOARD: "Painel de Controle",
          GROUPS: "Grupos",
          CLIENT: "Cliente",
          PROJECT: "Projeto",
          EMPLOYEE: "Funcionário",
        },
        CALENDAR: "Calendário",
        EMPLOYEE: {
          HOME: "Funcionário",
          EMAIL: "Email",
          TASKS: "Tarefas",
        },
      },
    },
  },
};
