import { Component, OnInit } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ChatService } from "../../chat.service";
import { Contact } from "app/auth/models/chat/contact.model";

@Component({
  selector: "app-chat-active-sidebar",
  templateUrl: "./chat-active-sidebar.component.html",
})
export class ChatActiveSidebarComponent implements OnInit {
  public chatUser: Contact;

  constructor(
    private _chatService: ChatService,
    private _coreSidebarService: CoreSidebarService
  ) {}

  toggleSidebar(name) {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  ngOnInit(): void {
    this._chatService.selectedChatUser.subscribe((res: Contact) => {
      if (res) this.chatUser = res;
    });
  }
}
