export const locale = {
  lang: "en",
  data: {
    MENU: {
      PAGES: {
        DASHBOARD: {
          DASHBOARD: "Dashboard",
          GROUPS: "Groups",
          CLIENT: "Client",
          PROJECT: "Project",
          EMPLOYEE: "Employee",
        },
        CALENDAR: "Calendar",
        TASKS: "Tasks",
        EMAIL: "Email",
        CHAT: "Chat",
      },
    },
  },
};
