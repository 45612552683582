import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { CoreSidebarModule } from "@core/components";
import { CoreCommonModule } from "@core/common.module";
import { ChatContentComponent } from "./chat-content/chat-content.component";
import { ChatActiveSidebarComponent } from "./chat-sidebars/chat-active-sidebar/chat-active-sidebar.component";
import { ChatSidebarComponent } from "./chat-sidebars/chat-sidebar/chat-sidebar.component";
import { ChatUserSidebarComponent } from "./chat-sidebars/chat-user-sidebar/chat-user-sidebar.component";
import { ChatComponent } from "./chat.component";
import { ChatService } from "./chat.service";
import { NavbarModule } from "app/layout/components/navbar/navbar.module";
import { TimezonePipe } from "@core/pipes/timeZone.pipe";
import { PickerModule } from "@ctrl/ngx-emoji-mart";

const routes: Routes = [
  {
    path: "chat",
    component: ChatComponent,
  },
];

@NgModule({
  declarations: [
    ChatComponent,
    ChatContentComponent,
    ChatSidebarComponent,
    ChatUserSidebarComponent,
    ChatActiveSidebarComponent,
  ],
  imports: [
    CommonModule,
    CoreSidebarModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    PerfectScrollbarModule,
    NgbModule,
    NavbarModule,
    NgbTooltipModule,
    PickerModule,
  ],
  providers: [ChatService, TimezonePipe],
})
export class ChatModule {}
