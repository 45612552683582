<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown">
    <i class="ficon" data-feather="bell"></i>
    <span
      class="badge badge-pill badge-danger badge-up"
      *ngIf="notificationLength > 0"
    >
      {{ notificationLength }}
    </span>
  </a>
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{ notificationLength }}
          Nouveau(x)
        </div>
      </div>
    </li>

    <li
      *ngIf="notificationLength > 0"
      class="scrollable-container media-list"
      [perfectScrollbar]
    >
      <a
        *ngFor="let notification of allNotifications"
        [routerLink]="notification?.link"
        class="d-flex"
      >
        <ng-container [ngSwitch]="notification?.type?.toLowerCase()">
          <!-- Type Message -->
          <ng-template ngSwitchCase="message">
            <div
              (click)="selectChat(notification?.id)"
              class="media d-flex align-items-start"
            >
              <div class="media-left">
                <div class="avatar">
                  <img
                    *ngIf="notification?.imageUrl"
                    [src]="viewPhoto(notification?.imageUrl)"
                    class="object-fit-cover size-40"
                    alt="avatar"
                  />
                  <div
                    *ngIf="!notification?.imageUrl"
                    class="size-40 d-flex justify-content-center align-items-center"
                  >
                    <span>{{ notification?.title | initials }}</span>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <span class="media-heading d-flex justify-content-between">
                  <span class="font-weight-bolder">{{
                    notification?.title
                  }}</span>
                  <span class="text-muted">
                    {{ notification?.date | date : "hh:mm le dd/MM/yyyy" }}
                  </span>
                </span>
                <small class="notification-text">{{
                  notification?.message
                }}</small>
              </div>
            </div>
          </ng-template>

          <!-- Type Task (Todo) -->
          <ng-template ngSwitchCase="task">
            <div class="media d-flex align-items-start">
              <div class="media-left">
                <div class="avatar bg-light-success">
                  <div class="avatar-content">
                    <i class="avatar-icon" [data-feather]="'check-square'"></i>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <p class="media-heading" [innerHTML]="notification?.title"></p>
                <small
                  class="notification-text no-m m-0 p-0"
                  [innerHTML]="notification?.message?.trim()"
                ></small>
              </div>
            </div>
          </ng-template>

          <!-- Type Event -->
          <ng-template ngSwitchCase="event">
            <div class="media d-flex align-items-start">
              <div class="media-left">
                <div class="avatar bg-light-danger">
                  <div class="avatar-content">
                    <i class="avatar-icon" [data-feather]="'calendar'"></i>
                  </div>
                </div>
              </div>
              <div class="media-body">
                <p class="media-heading" [innerHTML]="notification?.title"></p>
                <small
                  class="notification-text no-m m-0 p-0"
                  [innerHTML]="
                    notification?.message
                      ? notification?.message?.trim()
                      : 'Pas de description'
                  "
                ></small>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </a>
    </li>

    <li class="dropdown-menu-footer">
      <button
        class="btn btn-primary btn-block"
        [disabled]="allNotifications?.length === 0"
        (click)="markAsRead()"
      >
        Lire toutes les notifications
      </button>
    </li>
  </ul>
</li>
