import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, ApiResponse } from "app/auth/models";
import { mapUser } from "../helpers/mapping";
import { Router } from "@angular/router";
import { SocialAuthService } from "@abacritt/angularx-social-login";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUser: Observable<User>;

  private currentUserSubject: BehaviorSubject<User>;
  public onUserChange: BehaviorSubject<any>;

  constructor(
    private _http: HttpClient,
    private router: Router,
    private socialAuthService: SocialAuthService
  ) {
    this.onUserChange = new BehaviorSubject({});
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this._http
      .post<ApiResponse<User>>(`${environment.apiUrl}/api/User/Login`, {
        username,
        password,
      })
      .pipe(
        map((response) => {
          if (response.data && response.status == "success") {
            const user = mapUser(response?.data);
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);

            return user;
          } else {
            return null;
          }
        })
      );
  }

  toggleRememberMe() {
    const user = this.currentUserSubject.value;
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  register(user: User) {
    return this._http
      .post<ApiResponse<User>>(`${environment.apiUrl}/api/User/Register`, user)
      .pipe(
        map((response) => {
          const user = mapUser(response?.data);
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  loginWithGoogle(googleUser: any) {
    const googleLoginModel = {
      email: googleUser.email,
      googleId: googleUser.id,
      firstName: googleUser.firstName,
      lastName: googleUser.lastName,
      photoUrl: googleUser.photoUrl,
    };

    return this._http
      .post<ApiResponse<User>>(
        `${environment.apiUrl}/api/User/LoginWithGoogle`,
        googleLoginModel
      )
      .pipe(
        map((response) => {
          if (response.data && response.status === "success") {
            const user = mapUser(response?.data);
            localStorage.setItem("currentUser", JSON.stringify(user));
            this.currentUserSubject.next(user);
            return user;
          } else {
            return null;
          }
        })
      );
  }

  async logout() {
    try {
      document.title = "My Office";
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentClient");
      localStorage.removeItem("currentProject");

      this.currentUserSubject.next(null);

      try {
        const isSignedIn = await this.socialAuthService.authState
          .pipe(
            map((user) => !!user),
            catchError(() => from([false]))
          )
          .toPromise();

        if (isSignedIn) {
          await this.socialAuthService.signOut(true);
        }
      } catch (error) {
        console.warn("Google sign out failed:", error);
      }

      if (!sessionStorage.getItem("reloaded")) {
        sessionStorage.setItem("reloaded", "true");
        location.reload();
      } else {
        sessionStorage.removeItem("reloaded");
        this.router.navigate(["/dashboard"]);
      }
    } catch (error) {
      console.error("Logout failed:", error);
      this.router.navigate(["/dashboard"]);
    }
  }

  validateToken(): Observable<boolean> {
    if (!this.getToken()) {
      return of(false);
    }
    return this._http
      .get<any>(`${environment.apiUrl}/api/User/ValidateToken`)
      .pipe(
        map((response) => {
          return response?.data === true;
        }),
        catchError(() => of(false))
      );
  }

  getToken(): string | null {
    const user = localStorage.getItem("currentUser");
    if (user) {
      return JSON.parse(user).token;
    }
    return null;
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  deleteAccount() {
    return this._http.delete<ApiResponse<any>>(
      `${environment.apiUrl}/api/User/DeleteUser`
    );
  }
}
