import { NgModule } from "@angular/core";

import { TablerIconsModule } from "angular-tabler-icons";
import {
  IconTree,
  IconHome,
  IconHome2,
  IconSitemap,
  IconCoffee,
  IconCalendarMonth,
  IconMessageDots,
  IconListCheck,
  IconSpeakerphone,
  IconListDetails,
  IconCalendarWeek,
} from "angular-tabler-icons/icons";

const icons = {
  IconTree,
  IconHome,
  IconHome2,
  IconSitemap,
  IconCoffee,
  IconCalendarMonth,
  IconMessageDots,
  IconListCheck,
  IconSpeakerphone,
  IconListDetails,
  IconCalendarWeek,
};

@NgModule({
  imports: [TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
