J'accepte les
<a (click)="modalOpenSLCIM(modalSLCIM)" href="javascript:void(0);">
  Termes & Conditions
</a>

<ng-template #modalSLCIM let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">Termes & Conditions</h4>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <p>
      En utilisant cette application CRM, vous acceptez les termes et conditions
      suivants. Cette application est conçue pour aider les petites entreprises
      à gérer leurs clients, leurs ventes et leurs opérations quotidiennes.
    </p>

    <p>
      1. Utilisation de l'application : Vous acceptez d'utiliser cette
      application uniquement à des fins légales et conformément à toutes les
      lois et réglementations applicables.
    </p>

    <p>
      2. Propriété intellectuelle : Tous les contenus, marques, logos et autres
      propriétés intellectuelles présents dans cette application sont la
      propriété de leurs détenteurs respectifs. Vous ne pouvez pas utiliser ces
      éléments sans autorisation préalable.
    </p>

    <p>
      3. Confidentialité et protection des données : Nous nous engageons à
      assurer la protection absolue de vos données. Vos informations
      personnelles et professionnelles ne seront en aucun cas vendues, louées,
      partagées ou transmises à des tiers. Nous mettons en place des mesures de
      sécurité strictes pour protéger vos données contre tout accès non
      autorisé, modification, divulgation ou destruction.
    </p>

    <p>
      4. Utilisation des données : Les données collectées via notre application
      sont strictement limitées à un usage interne pour le bon fonctionnement du
      service. Elles ne seront jamais utilisées à des fins commerciales,
      publicitaires ou de profilage. Vous gardez le contrôle total sur vos
      données et pouvez demander leur suppression à tout moment.
    </p>

    <p>
      5. Limitation de responsabilité : Nous ne serons pas responsables des
      dommages directs, indirects, spéciaux ou consécutifs résultant de
      l'utilisation ou de l'incapacité à utiliser cette application.
    </p>

    <p>
      6. Modifications des termes : Nous nous réservons le droit de modifier ces
      termes et conditions à tout moment. Les modifications seront effectives
      dès leur publication sur cette application.
    </p>

    <p>
      7. Résiliation : Nous nous réservons le droit de résilier ou de suspendre
      votre accès à cette application à tout moment, sans préavis, pour toute
      violation de ces termes et conditions.
    </p>

    <p>
      8. Contact : Si vous avez des questions concernant ces termes et
      conditions, veuillez nous contacter à l'adresse email suivante :
      n.ouldterki&#64;gmail.com.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="acceptTerms()"
      rippleEffect
    >
      Accept
    </button>
  </div>
</ng-template>
