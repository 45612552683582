import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { first, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

import { CoreConfigService } from "@core/services/config.service";
import { Router } from "@angular/router";
import { AuthenticationService } from "app/auth/service";
import { environment } from "environments/environment";
import {
  GoogleLoginProvider,
  SocialAuthService,
} from "@abacritt/angularx-social-login";
import { JwtHelperService } from "@auth0/angular-jwt";
declare const google: any;

@Component({
  selector: "app-auth-register",
  templateUrl: "./auth-register.component.html",
  styleUrls: ["./auth-register.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthRegisterComponent implements OnInit {
  public coreConfig: any;
  public passwordTextType: boolean;
  public registerForm: UntypedFormGroup;
  public submitted = false;
  public error = "";
  public loading = false;
  public returnUrl: string;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private jwtHelper: JwtHelperService
  ) {
    if (this._authenticationService.currentUserValue) {
      this._router.navigate(["/"]);
    }
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  get f() {
    return this.registerForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this._authenticationService
      .register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data?.token) {
            this.loading = false;
            this._router.navigate(["/dashboard"]);
          }
        },
        (error) => {
          if (error == null) {
            console.error(error);
          } else {
            this.error = error;
          }

          this.loading = false;
        }
      );
  }

  ngOnInit(): void {
    this.initializeGoogleSignIn();
    this.registerForm = this._formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      username: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      terms: [null, Validators.requiredTrue],
    });

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.loading = true;
        this._authenticationService
          .loginWithGoogle(user)
          .pipe(first())
          .subscribe({
            next: (data) => {
              if (data?.token) {
                this.loading = false;
                this._router.navigate([this.returnUrl]);
              }
            },
            error: (error) => {
              this.error = "Échec de la connexion avec Google";
              this.loading = false;
            },
          });
      }
    });
  }

  initializeGoogleSignIn(): void {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (response) => {
        const decodedToken = this.jwtHelper.decodeToken(response.credential);
        const googleLoginModel = {
          email: decodedToken.email,
          googleId: decodedToken.sub,
          firstName: decodedToken.given_name,
          lastName: decodedToken.family_name,
          photoUrl: decodedToken.picture,
        };

        this._authenticationService
          .loginWithGoogle(googleLoginModel)
          .pipe(first())
          .subscribe({
            next: (data) => {
              if (data?.token) {
                this.loading = false;
                this._router.navigate(["/dashboard"]);
              }
            },
            error: (error) => {
              this.error = "Échec de la connexion avec Google";
              this.loading = false;
            },
          });

        if (response.credential) {
        }
      },
    });

    google.accounts.id.renderButton(
      document.getElementById("google-signup-button"),
      {
        locale: "fr",
        size: "large",
        theme: "outline",
      }
    );
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
