export class Todo {
  id? = 0;
  title = "";
  dueDate = "";
  description = "";
  assignee: Assignee;
  assigneeId: number;
  creatorId: number;
  clientId: number;
  projectId: number;
  tags = [];
  completed = false;
  deleted = false;
  important = false;
  order: number;
}

export class Assignee {
  id: number;
  fullname = "";
  avatar = "";
}
