import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { environment } from "environments/environment";
import { NavbarService } from "../navbar.service";
import { User } from "app/auth/models";
import { ChatService } from "app/features/chat/chat.service";

@Component({
  selector: "app-navbar-notification",
  templateUrl: "./navbar-notification.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class NavbarNotificationComponent implements OnInit {
  public allNotifications: any[] = [];

  public notificationLength: number = 0;
  currentUser: User;

  constructor(
    private _notificationsService: NotificationsService,
    private _navbarService: NavbarService,
    private _chatService: ChatService
  ) {}

  selectChat(conversationId: number) {
    this._chatService.selectConversation(null, conversationId);
  }

  ngOnInit(): void {
    this._navbarService.onUserChange.subscribe((user) => {
      if (user?.id) {
        this._notificationsService.user.next(user);
        this.currentUser = user;
        this._notificationsService.subscribeToNotification(user?.id);
      }
    });

    this._notificationsService.onNotificationsChange.subscribe(
      (result: any[]) => {
        this.notificationLength = result?.length;
        this.allNotifications = result;
      }
    );
  }

  viewPhoto(photo: string): string {
    if (!photo) {
      return;
    }
    return `${environment.apiUrl}/api/blob/view/${photo}`;
  }

  markAsRead(): void {
    this._notificationsService.markNotificationAsSeen(this.currentUser?.id);
  }
}
