import {
  Component,
  OnDestroy,
  OnInit,
  HostBinding,
  HostListener,
  ViewEncapsulation,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";

import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

import { AuthenticationService } from "app/auth/service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { User } from "app/auth/models";
import { Router } from "@angular/router";
import { ProfileService } from "app/features/profile/profile.service";
import { NavbarService } from "./navbar.service";
import { ClientService } from "app/features/career/services/client.service";
import { Client } from "app/auth/models/client/client.model";
import { ProjectService } from "app/features/career/services/project.service";
import { NotificationsService } from "./navbar-notification/notifications.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [ProfileService],
})
export class NavbarComponent implements OnInit, OnDestroy {
  currentClient: Client;
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  avatar: string = null;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  isLoading: boolean = true;
  isProfilePage: boolean = false;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    private _translateService: TranslateService,
    private _navbarService: NavbarService,
    private _authenticationService: AuthenticationService,
    private _clientService: ClientService,
    private _projectService: ProjectService,
    private _notificationsService: NotificationsService
  ) {
    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      fr: {
        title: "French",
        flag: "fr",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    this._unsubscribeAll = new Subject();
  }

  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  setLanguage(language): void {
    this.selectedLanguage = language;

    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  toggleDarkSkin() {
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  logout() {
    this._clientService.resetService();
    this._projectService.resetService();
    this._authenticationService.logout();
    this._router.navigate(["/authentication/login"]);
  }

  ngOnInit(): void {
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: any) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    if (this.coreConfig.layout.type == "horizontal") {
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
    this.isLoading = true;

    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this._clientService.onCurrentClientChange.subscribe((client: Client) => {
      this.currentClient = client;
    });

    if (this.currentUser?.token) {
      this._navbarService.getCurrentUser().subscribe(
        (user) => {
          if (user && user.token) {
            this.currentUser = user;
            this._notificationsService.getNotifications(this.currentUser?.id);
          }
        },
        (error) => {
          this._authenticationService.logout();
        }
      );
    }
    this.currentClient = JSON.parse(localStorage.getItem("currentClient"));
  }

  deleteAccount() {
    Swal.fire({
      title: "Suppression du compte",
      text: "Êtes-vous sûr de vouloir supprimer votre compte ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then((result) => {
      if (result.value) {
        this._authenticationService.deleteAccount().subscribe((result) => {
          if (result) {
            Swal.fire({
              title: "Supprimé !",
              text: "Votre compte a été supprimé. Vous allez être redirigé vers la page de connexion.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this._authenticationService.logout();
              this._router.navigate(["/authentication/login"]);
            });
          } else {
            Swal.fire({
              title: "Erreur",
              text: "Une erreur est survenue lors de la suppression de votre compte.",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Annulé",
          text: "Votre compte n'a pas été supprimé.",
          icon: "info",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
