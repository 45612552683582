import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter",
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, key: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText?.toLowerCase();

    return items.filter((item) => {
      const value = this.resolveProperty(item, key);
      return value?.toLowerCase().includes(searchText);
    });
  }

  private resolveProperty(item: any, key: string): any {
    return key
      .split(".")
      .reduce((prev, curr) => (prev ? prev[curr] : null), item);
  }
}
