import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, Observable, Subscribable } from "rxjs";
import { environment } from "environments/environment";
import { ApiResponse, Check, User } from "app/auth/models";
import { NavbarService } from "app/layout/components/navbar/navbar.service";
import { Apollo } from "apollo-angular";
import { USER_SUBSCRIPTION } from "app/apollo/requests/subscriptions";

@Injectable()
export class ProfileService implements Resolve<any> {
  public check: Check | any = {};
  public account: User;

  public onCheckChange: BehaviorSubject<any> = new BehaviorSubject(null);
  public onAccountChange: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _navbarService: NavbarService,
    private _apollo: Apollo
  ) {
    this.onCheckChange = new BehaviorSubject({});
    this.onAccountChange = new BehaviorSubject({});
  }

  GetCurrentAccount() {
    const user = JSON.parse(localStorage.getItem("currentUser"));

    if (user?.token) {
      this._navbarService.onUserChange.subscribe((result) => {
        if (result) {
          this.account = result;
          this.onAccountChange.next(this.account);
          if (this.account?.id) {
            this.subscribeToUser(this.account?.id);
          }
        }
      });
    }
  }

  private subscribeToUser(userId: number): void {
    this._apollo
      .subscribe({
        query: USER_SUBSCRIPTION,
        variables: { userId },
      })
      .subscribe((result: any) => {
        if (result.data.onUserUpdate) {
          this.account = result?.data?.onUserUpdate;
        }
      });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.manageRoute(route, state)]).then((res) => {
        resolve(res);
      }, reject);
    });
  }

  manageRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.account) {
      return Promise.all([Promise.resolve(this.account)]);
    } else {
      return Promise.all([this.GetCurrentAccount()]);
    }
  }

  getCheck(): Promise<Check> {
    const url = `${environment.apiUrl}/api/Check/GetCheckToday/${this.account?.id}`;
    return new Promise((resolve, reject) => {
      this._httpClient.get(url).subscribe((response: ApiResponse<Check>) => {
        if (response.status === "success") {
          this.check = response.data;
          this.onCheckChange.next(this.check);
        }

        resolve(this.check);
      }, reject);
    });
  }

  checkUpdate(check: Check) {
    this.check = check;
    this.check.userId = this.account.id;

    this.onCheckChange.next(this.check);
  }

  accountUpdate(account: User) {
    this.account = account;

    if (this.onAccountChange) {
      this.onAccountChange.next(this.account);
    }
  }

  postUpdatedAccount(account: User) {
    const url = `${environment.apiUrl}/api/User/UpdateUser`;
    return new Promise((resolve, reject) => {
      this._httpClient.patch(url, { ...account }).subscribe((response) => {
        resolve(response);
      }, reject);
    });
  }

  postUpdatedCheck(check: Check) {
    check.userId = this.account.id;
    const url = `${environment.apiUrl}/api/Check/UpdateCheck`;
    return new Promise((resolve, reject) => {
      this._httpClient.put(url, { ...check }).subscribe((response) => {
        this.getCheck();
        resolve(response);
      }, reject);
    });
  }

  updateUserPhoto(file: FormData) {
    const url = `${environment.apiUrl}/api/User/UpdateUserPhoto`;
    return new Promise((resolve, reject) => {
      this._httpClient
        .patch(url, file)
        ?.subscribe((response: ApiResponse<string>) => {
          if (response.status === "success") {
            this.accountUpdate(this.account);
          }
          resolve(response);
        }, reject);
    });
  }

  deleteUserPhoto() {
    const url = `${environment.apiUrl}/api/User/DeleteUserPhoto`;
    return new Promise((resolve, reject) => {
      this._httpClient
        .delete(url)
        ?.subscribe((response: ApiResponse<string>) => {
          resolve(response);
        }, reject);
    });
  }
}
