import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timezone",
})
export class TimezonePipe implements PipeTransform {
  transform(value: Date | string): string {
    // Convertir l'entrée en objet Date si elle est sous forme de chaîne
    const date = typeof value === "string" ? new Date(value) : value;

    // Formater la date dans le format "yyyy-MM-ddTHH:mm:ss"
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    // Ajouter les millisecondes avec trois chiffres
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

    // Calculer l'offset UTC
    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.abs(offset / 60)
      .toString()
      .padStart(2, "0");
    const offsetMinutes = (Math.abs(offset) % 60).toString().padStart(2, "0");
    const offsetSign = offset >= 0 ? "+" : "-";

    // Combiner toutes les parties pour obtenir le format souhaité
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;
  }
}
