import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "terms-modal",
  templateUrl: "terms-modal.component.html",
  styleUrls: ["./terms-modal.component.scss"],
})
export class TermsModalComponent implements OnInit {
  @Input() termsControl: any;
  constructor(private modalService: NgbModal) {}

  ngOnInit() {}

  acceptTerms() {
    this.termsControl.setValue(true);
    this.modalService.dismissAll();
  }

  modalOpenSLCIM(modalSLCIM) {
    this.modalService.open(modalSLCIM, { scrollable: true });
  }
}
