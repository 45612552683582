<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <a class="brand-logo" href="javascript:void(0);">
      <img
        src="{{ coreConfig.app.appLogoImage }}"
        alt="brand-logo"
        height="28"
      />
      <h2 class="brand-text text-primary ml-1">
        {{ coreConfig.app.appName }}
      </h2>
    </a>

    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          [src]="'assets/images/pages/login-v2.svg'"
          alt="My Office"
        />
      </div>
    </div>

    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">Bienvenue à toi ! 👋</h2>
        <p class="card-text mb-2">
          Connectez-vous à votre compte pour continuer.
        </p>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="login-email">
              Nom d'utilisateur
            </label>
            <input
              type="text"
              formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              placeholder="jdoe/jhondoe@email.com"
              aria-describedby="login-email"
              autofocus=""
              tabindex="1"
            />
            <div
              *ngIf="submitted && f.username.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.username.errors.required">
                Le nom d'utilisateur est requis
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Mot de passe</label>
              <a routerLink="/authentication/forgot-password">
                <small>Mot de passe oublié ?</small>
              </a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
                placeholder="············"
                aria-describedby="login-password"
                tabindex="2"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">
                Le mot de passe est requis
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                id="remember-me"
                (click)="toggleRememberMe()"
                type="checkbox"
                tabindex="3"
              />
              <label class="custom-control-label" for="remember-me">
                Se Rappeler de moi
              </label>
            </div>
          </div> -->
          <button
            [disabled]="loading"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect
          >
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >Se connecter
          </button>
        </form>
        <p class="text-center mt-2">
          <span>Nouveau sur la plateforme ?</span
          ><a routerLink="/authentication/register"
            ><span>&nbsp;Créer votre compte</span></a
          >
        </p>
        <div class="divider my-2">
          <div class="divider-text">Sinon</div>
        </div>
        <div class="auth-footer-btn d-flex justify-content-center">
          <a
            id="google-signin-button"
            href="javascript:void(0)"
            (click)="signInWithGoogle()"
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
