import {
  Directive,
  ElementRef,
  Input,
  Inject,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import * as Feather from "feather-icons";

@Directive({
  selector: "[data-feather]",
})
export class FeatherIconDirective implements OnChanges {
  // Private
  private _nativeElement: any;

  @Input("data-feather") name!: string;
  @Input() class!: string;
  @Input() size!: string;
  @Input() inner!: boolean;

  /**
   * Constructor
   *
   * @param {ElementRef} _elementRef
   */
  constructor(
    @Inject(ElementRef) private _elementRef: ElementRef,
    @Inject(ChangeDetectorRef) private _changeDetector: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // Get the native element
    this._nativeElement = this._elementRef.nativeElement;

    // SVG parameters
    this.name = changes?.name ? changes?.name.currentValue : "";
    this.size = changes?.size ? changes?.size.currentValue : "14"; // Set default size 14
    this.class = changes?.class ? changes?.class.currentValue : "";

    // Vérifier si l'icône existe dans Feather
    if (this.name && Feather.icons[this.name]) {
      // Create SVG
      const svg = Feather.icons[this.name].toSvg({
        class: this.class,
        width: this.size,
        height: this.size,
      });

      if (this.inner) {
        this._nativeElement.innerHTML = svg;
      } else {
        const parent = this._nativeElement.parentNode;
        if (parent) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = svg;
          const svgElement = tempDiv.firstChild;
          parent.replaceChild(svgElement, this._nativeElement);
        }
      }
      this._changeDetector.markForCheck();
    } else {
      console.warn(`Feather icon "${this.name}" not found`);
    }
  }
}
