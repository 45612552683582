import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { takeUntil, first } from "rxjs/operators";
import { Subject } from "rxjs";

import { AuthenticationService } from "app/auth/service";
import { CoreConfigService } from "@core/services/config.service";
import {
  SocialAuthService,
  GoogleLoginProvider,
} from "@abacritt/angularx-social-login";
import { environment } from "environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
declare const google: any;
@Component({
  selector: "app-auth-login-v2",
  templateUrl: "./auth-login.component.html",
  styleUrls: ["./auth-login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLoginComponent implements OnInit {
  public coreConfig: any;
  public loginForm: UntypedFormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = "";
  public passwordTextType: boolean;

  private _unsubscribeAll: Subject<any>;

  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private jwtHelper: JwtHelperService
  ) {
    if (this._authenticationService.currentUserValue) {
      this._router.navigate(["/"]);
    }

    this._unsubscribeAll = new Subject();

    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        menu: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        customizer: false,
        enableLocalStorage: false,
      },
    };
  }

  get f() {
    return this.loginForm.controls;
  }

  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this._authenticationService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe((data) => {
        if (data != null) {
          if (data?.token) {
            this.loading = false;
            this.submitted = false;
            this._router.navigate([this.returnUrl]);
          }
        } else {
          this.error = "Invalid email or password";
          this.loading = false;
        }
      });
  }

  toggleRememberMe() {
    this._authenticationService.toggleRememberMe();
  }

  ngOnInit(): void {
    this.initializeGoogleSignIn();
    this.loginForm = this._formBuilder.group({
      username: [null, [Validators.required]],
      password: [null, Validators.required],
    });

    this.returnUrl = this._route.snapshot.queryParams["returnUrl"] || "/";

    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
      });

    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.loading = true;
        this._authenticationService.loginWithGoogle(user).subscribe((data) => {
          if (data?.token) {
            this.loading = false;
            this._router.navigate([this.returnUrl]);
          }
        });
      }
    });
  }

  initializeGoogleSignIn(): void {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (response) => {
        const decodedToken = this.jwtHelper.decodeToken(response.credential);
        const googleLoginModel = {
          email: decodedToken.email,
          googleId: decodedToken.sub,
          firstName: decodedToken.given_name,
          lastName: decodedToken.family_name,
          photoUrl: decodedToken.picture,
        };
        this._authenticationService
          .loginWithGoogle(googleLoginModel)
          .subscribe((data) => {
            if (data?.token) {
              this.loading = false;
              this._router.navigate([this.returnUrl]);
            }
          });

        if (response.credential) {
          this.loading = false;
          this._router.navigate([this.returnUrl]);
        }
      },
    });

    google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        locale: "fr",
        size: "large",
        theme: "outline",
      }
    );
  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
