import { CoreConfig } from "@core/types";

export const coreConfig: CoreConfig = {
  app: {
    appName: "My Office",
    appTitle: "My Office",
    appLogoImage: "assets/images/logo/logo.svg",
    appLanguage: "fr",
  },
  layout: {
    skin: "default", // default, dark, bordered, semi-dark
    type: "horizontal", // vertical, horizontal
    animation: "fadeIn", // fadeInLeft, zoomIn , fadeIn, none
    menu: {
      hidden: false, // Boolean: true, false
      collapsed: false, // Boolean: true, false
    },
    navbar: {
      hidden: false, // Boolean: true, false
      type: "floating-nav", // navbar-static-top, fixed-top, floating-nav, d-none
      background: "navbar-light", // navbar-light. navbar-dark
      customBackgroundColor: false, // Boolean: true, false
      backgroundColor: "", // BS color i.e bg-primary, bg-success
    },
    footer: {
      hidden: false, // Boolean: true, false
      type: "footer-static", // footer-static, footer-sticky, d-none
      background: "footer-light", // footer-light. footer-dark
      customBackgroundColor: false, // Boolean: true, false
      backgroundColor: "", // BS color i.e bg-primary, bg-success
    },
    enableLocalStorage: true,
    customizer: false, // Boolean: true, false (Enable theme customizer)
    scrollTop: true, // Boolean: true, false (Enable scroll to top button)
    buyNow: false, // Boolean: true, false (Set false in real project, For demo purpose only)
  },
};
