import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Project } from "app/auth/models/project/project.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Pagination } from "app/auth/models/pagination.model";
import { Apollo } from "apollo-angular";
import { Projects } from "app/auth/models/project/projects.model";
import { PROJECT_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { ProjectParticipants } from "app/auth/models/project/projectParticipants.model";
import { ProjectCompact } from "app/auth/models/project/projectCompact.model";
import { EmployeeProject } from "app/auth/models/employeeProjects.model";

@Injectable({
  providedIn: "root",
})
export class ProjectService implements OnDestroy {
  private currentProject: Project;
  private pagination: Pagination = {
    page: 1,
    pageSize: 5,
  };
  private search: string = "all";

  public onPaginationChange: BehaviorSubject<Pagination>;
  public onCurrentProjectChange: BehaviorSubject<Project>;
  public onProjectsChanged: BehaviorSubject<Projects>;
  public onSearchProject: BehaviorSubject<string>;

  constructor(private _httpClient: HttpClient, private _apollo: Apollo) {
    this.currentProject = JSON.parse(localStorage.getItem("currentProject"));

    this.onPaginationChange = new BehaviorSubject(this.pagination);
    this.onCurrentProjectChange = new BehaviorSubject(new Project());
    this.onProjectsChanged = new BehaviorSubject(new Projects());
    this.onSearchProject = new BehaviorSubject(this.search);

    if (this.currentProject) {
      this.onCurrentProjectChange.next(this.currentProject);
    }

    this.onSearchProject.subscribe((search) => {
      this.search = search;
    });

    this.onPaginationChange.subscribe((pagination) => {
      if (pagination?.page && pagination?.pageSize) {
        this.pagination = pagination;
      }
    });

    this.subscribeToProjects();
  }

  getParticipants(): Observable<ProjectParticipants[]> {
    return this._httpClient.get<ProjectParticipants[]>(
      `${environment.apiUrl}/api/Project/GetParticipants`
    );
  }

  getProjectsByClientId(clientId: number): Observable<ProjectCompact[]> {
    return this._httpClient.get<ProjectCompact[]>(
      `${environment.apiUrl}/api/Project/GetProjectsByClientId/${clientId}`
    );
  }

  getProjects(clientId: number): void {
    this._httpClient
      .get(
        `${environment.apiUrl}/api/Project/GetProjects/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}/${clientId}`
      )
      .subscribe((projects: Projects) => {
        this.onProjectsChanged.next(projects);
      });
  }

  addProject(project: Project, clientId: number): Observable<Project> {
    return this._httpClient.post<Project>(
      `${environment.apiUrl}/api/Project/AddProject/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}/${clientId}`,
      project
    );
  }

  removeProject(projectId: number, clientId: number): Observable<any> {
    return this._httpClient.delete(
      `${environment.apiUrl}/api/Project/RemoveProject/${projectId}/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}/${clientId}`
    );
  }

  updateProject(project: Project, clientId: number): Observable<any> {
    return this._httpClient.put(
      `${environment.apiUrl}/api/Project/UpdateProject/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}/${clientId}`,
      project
    );
  }

  createProjectRequest(
    userId: number,
    projectId: number,
    clientId: number
  ): Observable<any> {
    return this._httpClient.post(
      `${environment.apiUrl}/api/EmployeeProject/CreateProjectRequest/${projectId}/${userId}/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}/${clientId}`,
      null
    );
  }

  private subscribeToProjects(): void {
    this._apollo
      .subscribe({
        query: PROJECT_SUBSCRIPTION,
      })
      .subscribe(({ data }: any) => {
        this.onProjectsChanged.next(data?.onProjectsChanged);
      });
  }

  saveProjectToLocalStorage(project: Project): void {
    localStorage.setItem("currentProject", JSON.stringify(project));
  }

  resetService(): void {
    this.onCurrentProjectChange.next(null);
  }

  ngOnDestroy(): void {
    this.onPaginationChange.unsubscribe();
    this.onCurrentProjectChange.unsubscribe();
    this.onProjectsChanged.unsubscribe();
  }
}
