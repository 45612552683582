// text-truncate.directive.ts
import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: "[textTruncate]",
})
export class TextTruncateDirective implements OnInit {
  @Input() maxLength = 200;
  @Input() postText = "";
  private isExpanded = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    const originalText = this.postText || this.el.nativeElement.innerHTML;
    if (originalText.length > this.maxLength) {
      this.setupTruncatedView(originalText);
    }
  }

  private setupTruncatedView(originalText: string) {
    // Vider le contenu actuel
    this.el.nativeElement.innerHTML = "";

    // Créer le conteneur principal avec flexbox
    const container = this.renderer.createElement("div");
    this.renderer.addClass(container, "truncate-container");
    this.renderer.setStyle(container, "display", "block");
    this.renderer.setStyle(container, "justify-content", "space-between");
    this.renderer.setStyle(container, "align-items", "flex-start");
    this.renderer.setStyle(container, "gap", "8px");

    // Conteneur pour le texte
    const textContainer = this.renderer.createElement("div");
    this.renderer.addClass(textContainer, "text-container");
    this.renderer.setStyle(textContainer, "flex", "1");

    // Créer le span pour le texte tronqué
    const textSpan = this.renderer.createElement("span");
    this.renderer.addClass(textSpan, "textSpanTruncate");
    const truncatedText = originalText.substring(0, this.maxLength) + "... ";
    this.renderer.setProperty(textSpan, "innerHTML", truncatedText);

    // Ajouter les styles d'animation au span
    this.renderer.setStyle(textSpan, "transition", "all 0.3s ease-in-out");
    this.renderer.setStyle(textSpan, "display", "inline-block");

    // Créer le conteneur pour le lien
    const linkContainer = this.renderer.createElement("div");
    this.renderer.setStyle(linkContainer, "flex-shrink", "0");
    this.renderer.addClass(linkContainer, "text-right");

    // Créer le lien "Voir plus"
    const toggleLink = this.renderer.createElement("a");
    this.renderer.setProperty(toggleLink, "innerHTML", "Voir plus");
    this.renderer.addClass(toggleLink, "text-primary");
    this.renderer.addClass(toggleLink, "toggle-link");
    this.renderer.setStyle(toggleLink, "text-decoration", "none");
    this.renderer.setStyle(toggleLink, "cursor", "pointer");
    this.renderer.setStyle(toggleLink, "white-space", "nowrap");

    // Gérer le clic avec animation
    this.renderer.listen(toggleLink, "click", (event) => {
      event.preventDefault();

      // Ajouter la classe d'animation
      this.renderer.addClass(textSpan, "animating");

      if (!this.isExpanded) {
        this.renderer.setProperty(textSpan, "innerHTML", originalText + " ");
        this.renderer.setProperty(toggleLink, "innerHTML", "Voir moins");
      } else {
        this.renderer.setProperty(textSpan, "innerHTML", truncatedText);
        this.renderer.setProperty(toggleLink, "innerHTML", "Voir plus");
      }

      // Retirer la classe d'animation après la transition
      setTimeout(() => {
        this.renderer.removeClass(textSpan, "animating");
      }, 300);

      this.isExpanded = !this.isExpanded;
    });

    // Assembler les éléments
    this.renderer.appendChild(textContainer, textSpan);
    this.renderer.appendChild(linkContainer, toggleLink);
    this.renderer.appendChild(container, textContainer);
    this.renderer.appendChild(container, linkContainer);
    this.renderer.appendChild(this.el.nativeElement, container);
  }
}
