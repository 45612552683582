import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TutorialService } from "./tour.service";
import { TutorialStep } from "app/auth/interfaces/tutorial-step.interface";
import { ClientService } from "../career/services/client.service";
import { ProjectService } from "../career/services/project.service";
import { CareerService } from "../career/services/career.service";

@Component({
  selector: "tour",
  templateUrl: "./tour.component.html",
  styleUrls: ["./tour.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TourComponent implements OnInit {
  public contentHeader: object;
  public backBtnClass = "btn btn-sm btn-outline-primary";
  public nextBtnClass = "btn btn-sm btn-primary btn-next";
  step = 1;
  client;
  project;

  constructor(
    private _tutorialService: TutorialService,
    private _clientService: ClientService,
    private _projectService: ProjectService,
    private _careerService: CareerService
  ) {
    this._clientService.onCurrentClientChange.subscribe((client) => {
      if (client) {
        this.client = client;
      } else {
        this.client = null;
      }
    });

    this._projectService.onCurrentProjectChange.subscribe((project) => {
      if (project) {
        this.project = project;
      } else {
        this.project = null;
      }
    });

    this._careerService.step.subscribe((step) => {
      this.step = step;
    });
  }

  public startTour() {
    this.manageStepByPathName(window.location.pathname);
    this._tutorialService.startTutorial();
  }

  ngAfterViewInit() {}

  manageStepByPathName(pathName: string) {
    this._tutorialService.generateTutorialSteps(
      pathName,
      this.client,
      this.project,
      this.step
    );
  }

  ngOnInit() {}
}
