import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "initials",
})
export class InitialsPipe implements PipeTransform {
  transform(fullname: string): any {
    return fullname
      ?.split(" ")
      .slice(0, 2)
      .map((n) => n[0])
      .join("");
  }
}
