import { gql } from "apollo-angular";

export const USER_SUBSCRIPTION = gql`
  subscription OnUserUpdate($userId: Int!) {
    onUserUpdate(userId: $userId) {
      id
      avatar
      birthdate
      email
      tjm
      firstname
      fullname
      about
      guidId
      jobTitle
      lastname
      phone
      status
      token
      username
      deleted
      addressId
      address {
        id
        street
        city
        state
        country
        zipCode
      }
    }
  }
`;

export const CONVERSATIONS_SUBSCRIPTION = gql`
  subscription OnConversationChangeReceived($userId: Int!) {
    onConversationChangeReceived(userId: $userId) {
      conversationId
      createdAt
      isGroup
      lastMessageAt
      messages {
        content
        conversationId
        isRead
        messageId
        senderId
        sentAt
        sender {
          about
          avatar
          email
          fullname
          deleted
          id
          phone
          status
        }
      }
      participants {
        conversationId
        joinedAt
        lastMessage
        unreadMessagesCount
        participantId
        userId
        user {
          about
          avatar
          email
          fullname
          deleted
          id
          phone
          status
        }
      }
    }
  }
`;

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNotificationChangeReceived($userId: Int!) {
    onNotificationChangeReceived(userId: $userId) {
      date
      id
      imageUrl
      link
      message
      title
      type
    }
  }
`;

export const TODO_UPDATED_SUBSCRIPTION = gql`
  subscription OnTodoUpdated($userId: Int!) {
    onTodoUpdated(userId: $userId) {
      order
      assigneeId
      creatorId
      clientId
      projectId
      description
      dueDate
      id
      tags
      title
      important
      completed
      deleted
      assignee {
        avatar
        email
        fullname
        deleted
        id
      }
    }
  }
`;

export const POST_SUBSCRIPTION = gql`
  subscription OnPostChange($size: Int!) {
    onPostChange(size: $size) {
      createdDate
      guidId
      id
      postCategory
      postImageUrl
      postText
      status
      tags
      title
      userId
      comments {
        commentText
        createdDate
        guidId
        id
        postId
        userId
        likes {
          commentId
          id
          likedAt
          userId
        }
        user {
          id
          about
          avatar
          email
          fullname
          deleted
          phone
          status
        }
      }
      likes {
        id
        likedAt
        postId
        userId
        user {
          id
          about
          avatar
          email
          fullname
          deleted
          phone
          status
        }
      }
      user {
        id
        about
        avatar
        email
        fullname
        deleted
        phone
        status
      }
    }
  }
`;

export const POSTSDRAFT_SUBSCRIPTION = gql`
  subscription OnPostDraftChange($userId: Int!) {
    onPostDraftChange(userId: $userId) {
      guidId
      id
      postCategory
      postImageUrl
      postText
      status
      tags
      title
      userId
    }
  }
`;

export const CLIENTS_SUBSCRIPTION = gql`
  subscription {
    onClientsChanged {
      clientList {
        id
        title
        contactId
        contact {
          id
          fullname
          avatar
          addressId
          address {
            id
            street
            city
            state
            country
            zipCode
          }
        }
        date
        description
        projects {
          projectId
          projectName
          description
          startDate
          endDate
          managerId
          manager {
            id
            fullname
            deleted
            avatar
          }
          employeeProjects {
            employeeProjectId
            employeeId
            projectId
          }
        }
      }
      totalCount
    }
  }
`;

export const CLIENT_SUBSCRIPTION = gql`
  subscription {
    onClientChanged {
      id
      title
      contactId
      contact {
        id
        fullname
        deleted
        avatar
        addressId
        address {
          id
          street
          city
          state
          country
          zipCode
        }
      }
      date
      description
      projects {
        projectId
        projectName
        description
        startDate
        endDate
        managerId
        manager {
          id
          fullname
          deleted
          avatar
        }
        employeeProjects {
          employeeId
          employeeProjectId
          projectId
          status
          role
          employee {
            id
            fullname
            deleted
            avatar
          }
        }
      }
    }
  }
`;

export const PROJECT_SUBSCRIPTION = gql`
  subscription {
    onProjectsChanged {
      projectList {
        projectId
        projectName
        description
        status
        category
        startDate
        endDate
        clientId
        managerId
        manager {
          id
          fullname
          deleted
          avatar
        }
        employeeProjects {
          employeeId
          employeeProjectId
          projectId
          status
          role
          employee {
            id
            fullname
            deleted
            avatar
          }
        }
      }
      totalCount
    }
  }
`;

export const EMPLOYEE_SUBSCRIPTION = gql`
  subscription {
    onEmployeeProjectChanged {
      employeeProjectId
      employeeId
      employee {
        id
        fullname
        deleted
        email
        avatar
      }
      status
      projectId
      role
    }
  }
`;

export const INVOICE_SUBSCRIPTION = gql`
  subscription OnInvoiceChanged($page: Int!, $pageSize: Int!, $userId: Int!) {
    onInvoiceChanged(page: $page, pageSize: $pageSize, userId: $userId) {
      invoices {
        id
        swiftCode
        accountHolderName
        localAccountNumber
        tax
        discount
        items {
          id
          service
          description
          price
          quantity
          total
          clientId
        }
        client {
          id
          title
          contact {
            id
            fullname
            deleted
            avatar
          }
          contactId
          date
          description
        }
        clientId
        issuedDate
        dueDate
        userId
        subTotal
        totalDue
        bankName
        iban
      }
      totalPages
    }
  }
`;

export const CALENDAR_SUBSCRIPTION = gql`
  subscription OnCalendarChange($userId: Int!) {
    onCalendarChange(userId: $userId) {
      id
      projectId
      guest {
        id
        fullname
        email
        avatar
        deleted
      }
      userId
      title
      start
      end
      allDay
      location
      description
      calendarId
      guestId
      clientId
    }
  }
`;
