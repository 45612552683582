<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <core-sidebar
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden card-menu-calendar d-flex flex-column"
          name="calendar-main-sidebar"
          overlayClass="body-content-overlay"
          collapsibleSidebar="bs-gt-lg"
        >
          <app-calendar-main-sidebar></app-calendar-main-sidebar>
        </core-sidebar>

        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                class="card-calendar"
                [options]="calendarOptions"
              ></full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <core-sidebar
      class="modal modal-slide-in fade event-sidebar"
      name="calendar-event-sidebar"
      overlayClass="modal-backdrop"
      hideOnEsc="true"
    >
      <app-calendar-event-sidebar></app-calendar-event-sidebar>
    </core-sidebar>
  </div>
</div>
