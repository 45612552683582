import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Apollo } from "apollo-angular";
import { CLIENTS_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { User } from "app/auth/models";
import { Client } from "app/auth/models/client/client.model";
import { ClientCompact } from "app/auth/models/client/clientCompact.model";
import { Clients } from "app/auth/models/client/clients.model";
import { Pagination } from "app/auth/models/pagination.model";
import { NavbarService } from "app/layout/components/navbar/navbar.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ClientService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private currentClient: Client;
  private pagination: Pagination = {
    page: 1,
    pageSize: 5,
  };
  private search: string = "all";

  public onPaginationChange: BehaviorSubject<Pagination>;
  public onCurrentClientChange: BehaviorSubject<Client>;
  public onClientsChanged: BehaviorSubject<Clients>;
  public onSearchClient: BehaviorSubject<string>;
  public currentUser: BehaviorSubject<User>;

  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo,
    private _navbarService: NavbarService
  ) {
    this.onPaginationChange = new BehaviorSubject(this.pagination);
    this.onCurrentClientChange = new BehaviorSubject(new Client());
    this.onClientsChanged = new BehaviorSubject(new Clients());
    this.onSearchClient = new BehaviorSubject(this.search);
    this.currentUser = new BehaviorSubject<User>(null);

    this._navbarService.onUserChange.subscribe((user) => {
      this.currentUser.next(user);
    });

    this.currentClient = JSON.parse(localStorage.getItem("currentClient"));

    if (this.currentClient) {
      this.onCurrentClientChange.next(this.currentClient);
    }

    this.onSearchClient.pipe(takeUntil(this.destroy$)).subscribe((search) => {
      this.search = search;
    });

    this.onPaginationChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((pagination) => {
        if (pagination?.page && pagination?.pageSize) {
          this.pagination = pagination;
        }
      });

    this.subscribeToClients();
  }

  getCompactedClients(): Observable<ClientCompact[]> {
    return (
      this.currentUser?.value?.id &&
      this._httpClient.get<ClientCompact[]>(
        `${environment.apiUrl}/api/Client/GetAllClients/${this.currentUser?.value?.id}`
      )
    );
  }

  getAllClients(): any {
    return this._httpClient.get(
      `${environment.apiUrl}/api/Client/GetClients/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}`
    );
  }

  getClients(): any {
    return this._httpClient.get(`${environment.apiUrl}/api/Client/GetClients`);
  }

  addClient(client: Client): Observable<any> {
    return (
      client?.contactId &&
      this._httpClient.post(
        `${environment.apiUrl}/api/Client/AddClient/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}`,
        client
      )
    );
  }

  removeClient(clientId: number): Observable<any> {
    return (
      clientId &&
      this._httpClient.delete(
        `${environment.apiUrl}/api/Client/RemoveClient/${clientId}/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}`
      )
    );
  }

  updateClient(client: Client): Observable<any> {
    return (
      client?.contactId &&
      this._httpClient.put(
        `${environment.apiUrl}/api/Client/UpdateClient/${this.pagination?.page}/${this.pagination?.pageSize}/${this.search}`,
        client
      )
    );
  }

  private subscribeToClients(): void {
    this._apollo
      .subscribe({
        query: CLIENTS_SUBSCRIPTION,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.onClientsChanged.next(data?.onClientsChanged);
      });
  }

  saveClientToLocalStorage(client: Client): void {
    localStorage.setItem("currentClient", JSON.stringify(client));
  }

  resetService(): void {
    this.onCurrentClientChange.next(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
