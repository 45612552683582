import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { EMPLOYEE_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { Check } from "app/auth/models";
import { EmployeeProject } from "app/auth/models/employeeProjects.model";
import { CalendarEvent } from "app/auth/models/event.model";
import { Todo } from "app/features/todo/todo.model";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CareerService {
  public step: BehaviorSubject<number>;
  public onEmployeeChanged: BehaviorSubject<EmployeeProject>;
  public onEmployeeProjectsChanged: BehaviorSubject<EmployeeProject[]>;
  constructor(private _httpClient: HttpClient, private _apollo: Apollo) {
    this.step = new BehaviorSubject(1);
    this.onEmployeeChanged = new BehaviorSubject(null);
    this.onEmployeeProjectsChanged = new BehaviorSubject([]);

    this.subscribeToProjects();
  }

  getTodosByProjectId(projectId: number): Observable<Todo[]> {
    return this._httpClient.get<Todo[]>(
      `${environment.apiUrl}/api/Todo/GetTodosByProjectId/${projectId}`
    );
  }

  getEventsByProjectId(projectId: number): Observable<CalendarEvent[]> {
    return this._httpClient.get<CalendarEvent[]>(
      `${environment.apiUrl}/api/Calendar/GetEventsByProjectId/${projectId}`
    );
  }

  getEmployeeProjects(projectId: number): Observable<any> {
    return this._httpClient.get<any>(
      `${environment.apiUrl}/api/EmployeeProject/GetEmployeeProjects/${projectId}`
    );
  }

  removeEmployeeProject(
    projectId: number,
    employeeId: number
  ): Observable<any> {
    return this._httpClient.delete(
      `${environment.apiUrl}/api/EmployeeProject/RemoveEmployeeProject/${projectId}/${employeeId}`
    );
  }

  getCurrentCheck(userId: number): Observable<Check> {
    const url = `${environment.apiUrl}/api/Check/GetCheckToday/${userId}`;
    return this._httpClient.get<Check>(url);
  }

  checkIn(check: Check): Observable<Check> {
    const url = `${environment.apiUrl}/api/Check/UpdateCheck`;
    return this._httpClient.patch<Check>(url, check);
  }

  updateEmployeeProject(employeeProject: EmployeeProject): Observable<any> {
    return this._httpClient.patch(
      `${environment.apiUrl}/api/EmployeeProject/UpdateEmployeeProject`,
      employeeProject
    );
  }

  private subscribeToProjects(): void {
    this._apollo
      .subscribe({
        query: EMPLOYEE_SUBSCRIPTION,
      })
      .subscribe(({ data }: any) => {
        this.onEmployeeProjectsChanged.next(data?.onEmployeeProjectChanged);
      });
  }
}
