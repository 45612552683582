<div class="auth-wrapper auth-v1 px-2">
  <div class="auth-inner py-2">
    <div class="card mb-0">
      <div class="card-body">
        <a href="javascript:void(0);" class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoImage }}"
            alt="brand-logo"
            height="28"
          />
          <h2 class="brand-text text-primary ml-1">
            {{ coreConfig.app.appName }}
          </h2>
        </a>

        <h4 class="card-title mb-1">Réinitialiser le mot de passe 🔒</h4>
        <p class="card-text mb-2">Entrez votre nouveau mot de passe.</p>

        <form
          class="auth-reset-password-form mt-2"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-new">Nouveau mot de passe</label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="newPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-new"
                tabindex="1"
                autofocus
                [ngClass]="{
                  'is-invalid error': submitted && f.newPassword.errors,
                  'is-valid': submitted && !f.newPassword.errors
                }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i>
                </span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.newPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.newPassword.errors }"
            >
              <div *ngIf="f.newPassword.errors.required">
                Le mot de passe est requis
              </div>
              <div *ngIf="f.newPassword.errors.minlength">
                Le mot de passe doit contenir au moins 8 caractères
              </div>
              <div *ngIf="f.newPassword.errors.pattern">
                Le mot de passe doit contenir au moins :
                <ul class="mb-0">
                  <li>Une lettre minuscule</li>
                  <li>Une lettre majuscule</li>
                  <li>Un chiffre</li>
                  <li>Un caractère spécial ({{ "@" }}$!%*?&)</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="reset-password-confirm">
                Confirmer le mot de passe
              </label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="confPasswordTextType ? 'text' : 'password'"
                formControlName="confirmPassword"
                class="form-control form-control-merge"
                placeholder="············"
                aria-describedby="reset-password-confirm"
                tabindex="2"
                [ngClass]="{
                  'is-invalid error': submitted && f.confirmPassword.errors,
                  'is-valid': submitted && !f.confirmPassword.errors
                }"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer">
                  <i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': confPasswordTextType,
                      'icon-eye': !confPasswordTextType
                    }"
                    (click)="toggleConfPasswordTextType()"
                  ></i>
                </span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.confirmPassword.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }"
            >
              <div *ngIf="f.confirmPassword.errors.required">
                La confirmation du mot de passe est requise
              </div>
              <div *ngIf="f.confirmPassword.errors.passwordMismatch">
                Les mots de passe ne correspondent pas
              </div>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="3" rippleEffect>
            Réinitialiser le mot de passe
          </button>
        </form>

        <p class="text-center mt-2">
          <a routerLink="/pages/authentication/login-v1">
            <i data-feather="chevron-left" class="mb-25"></i>
            Retour à la connexion
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
