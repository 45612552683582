import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthenticationService } from "../service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!this._authService.isAuthenticated()) {
      this._router.navigate(["authentication/login"], {
        queryParams: { returnUrl: state.url },
      });
      return of(false);
    }

    return this._authService.validateToken().pipe(
      map((isValid) => {
        if (!isValid) {
          this._router.navigate(["authentication/login"], {
            queryParams: { returnUrl: state.url },
          });
        }
        return isValid;
      }),
      catchError(() => {
        this._router.navigate(["authentication/login"]);
        return of(false);
      })
    );
  }
}
