import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private _http: HttpClient) {}

  forgotPassword(email: string): Observable<any> {
    return this._http.get(
      `${environment.apiUrl}/api/Auth/ForgotPassword/${email}`
    );
  }

  resetPassword(data: any): Observable<any> {
    return this._http.post(
      `${environment.apiUrl}/api/Auth/ResetPassword`,
      data
    );
  }
}
