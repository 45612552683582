import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { AuthenticationModule } from "./features/authentication/authentication.module";
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from "./auth/helpers";
import { MiscellaneousModule } from "./features/miscellaneous/miscellaneous.module";
import { EventInterceptor } from "./auth/helpers/event.interceptor";
import { CalendarModule } from "./features/calendar/calendar.module";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { HttpErrorInterceptor } from "./auth/helpers/http.interceptor";
import { ChatModule } from "./features/chat/chat.module";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { CreateApollo } from "./apollo/apollo";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import localeFr from "@angular/common/locales/fr";
import { registerLocaleData } from "@angular/common";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { IconsModule } from "@core/components/tabler-icons/tabler-icons.module";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@abacritt/angularx-social-login";
import { environment } from "environments/environment";
import { JWT_OPTIONS, JwtHelperService } from "@auth0/angular-jwt";

registerLocaleData(localeFr);

const appRoutes: Routes = [
  {
    path: "career",
    loadChildren: () =>
      import("./features/career/career.module").then((m) => m.CareerModule),
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./features/dashboard/post.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "authentication",
    loadChildren: () =>
      import("./features/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "cgu",
    loadChildren: () =>
      import("./features/cgu/cgu.module").then((m) => m.CguModule),
  },
  {
    path: "faq",
    loadChildren: () =>
      import("./features/faq/faq.module").then((m) => m.FaqModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./features/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "todo",
    loadChildren: () =>
      import("./features/todo/todo.module").then((m) => m.TodoModule),
    canActivate: [AuthGuard],
  },
  {
    path: "calendar",
    loadChildren: () =>
      import("./features/calendar/calendar.module").then(
        (m) => m.CalendarModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./features/chat/chat.module").then((m) => m.ChatModule),
  },
  {
    path: "flowchart",
    loadChildren: () =>
      import("./features/org-chart/org-chart.module").then(
        (m) => m.OrgChartModule
      ),
  },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/miscellaneous/error",
  },
];

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    AuthenticationModule,
    MiscellaneousModule,
    CalendarModule,
    ChatModule,
    HttpLinkModule,
    ApolloModule,
    Ng2FlatpickrModule,
    IconsModule,
    SocialLoginModule,
  ],
  providers: [
    JwtHelperService,
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EventInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const { link, cache } = CreateApollo();
        return {
          cache,
          link,
        };
      },
      deps: [HttpLink],
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, {
              oneTapEnabled: false,

              scopes: "email profile",
            }),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
