import { Injectable } from "@angular/core";
import { ShepherdService } from "angular-shepherd";
import { StepButton } from "app/auth/interfaces/step-button.interface";
import { TutorialStep } from "app/auth/interfaces/tutorial-step.interface";

@Injectable({
  providedIn: "root",
})
export class TutorialService {
  private readonly defaultNextBtn: StepButton = {
    text: "Suivant",
    type: "next",
    classes: "btn btn-sm btn-primary btn-next",
  };

  private readonly defaultBackBtn: StepButton = {
    text: "Retour",
    type: "back",
    classes: "btn btn-sm btn-outline-primary",
  };

  private readonly defaultCancelBtn: StepButton = {
    text: "Quitter",
    type: "cancel",
    classes: "btn btn-sm btn-outline-primary",
  };

  constructor(private shepherdService: ShepherdService) {}

  generateTutorialSteps(pathName, client, project, step): void {
    let steps: TutorialStep[] = null;

    if (pathName === "/dashboard") {
      steps = [
        {
          title: "Catégorie",
          text: "Filtrer les dernières publications par catégorie.",
          element: ".category-card",
          position: "bottom",
        },
        {
          title: "Publication",
          text: "Ceci est une publication.",
          element: ".post-card",
          position: "bottom",
        },
        {
          title: "Section droite",
          text: "Ajouter une publication.",
          element: ".card-add-post",
          position: "top",
        },
        {
          title: "Vos brouillons",
          text: "Modifier ou supprimer vos brouillons.",
          element: ".card-draft-post",
          position: "top",
        },
      ];
    }
    if (pathName === "/career") {
      if (step === 1) {
        steps = [
          {
            title: "Clients",
            text: "Sur cette page, vous pouvez voir les clients de l'entreprise. Sélectionnez un client pour pouvoir voir les projets associés.",
            element: "#client",
            position: "bottom",
          },
          {
            title: "Clients",
            text: "Vous pouvez ajouter un client en cliquant sur ce bouton.",
            element: ".client-add-btn",
            position: "bottom",
          },
          {
            title: "Clients",
            text: "Vous pouvez rechercher directement un client.",
            element: ".client-search",
            position: "bottom",
          },
          {
            title: "Clients",
            text: "D'autres actions sont disponibles pour chaque client.",
            element: ".client-actions",
            position: "bottom",
          },
        ];
      }
      if (client?.id && step === 2) {
        steps = [
          {
            title: "Projets",
            text: "Sur cette page, vous pouvez voir les projets du client. Sélectionnez un projet pour pouvoir voir vos tâches et avancements.",
            element: ".project-list",
            position: "bottom",
          },
          {
            title: "Projets",
            text: "Vous pouvez ajouter un projet en cliquant sur ce bouton.",
            element: ".project-add-btn",
            position: "bottom",
          },
          {
            title: "Projets",
            text: "Vous pouvez rechercher directement un projet.",
            element: ".project-search",
            position: "bottom",
          },
          {
            title: "Projets",
            text: "D'autres actions sont disponibles pour chaque projet.",
            element: ".project-actions",
            position: "bottom",
          },
        ];
      }
      if (client?.id && project?.projectId && step === 3) {
        steps = [
          {
            title: "Poste",
            text: "Sur cette étape, vous avez un compte rendu de vos tâches et de l'avancement du projet.",
            element: "#employee",
            position: "bottom",
          },
          {
            title: "Information",
            text: "Le détails de vos informations.",
            element: ".card-information",
            position: "bottom",
          },
          {
            title: "Information",
            text: "Visualisez la liste des tâches et événements à venir.",
            element: ".btn-information",
            position: "bottom",
          },
          {
            title: "Participant",
            text: "Gestion des participants si vous êtes propriétaire du projet.",
            element: ".card-participant",
            position: "bottom",
          },
          {
            title: "Tâches",
            text: "Progression des tâches.",
            element: ".card-task",
            position: "bottom",
          },
          {
            title: "Pointage journalier",
            text: "Faire un pointage journalier qui va être pris en compte dans vos factures.",
            element: ".card-check",
            position: "bottom",
          },
          {
            title: "Projet",
            text: "Détails du projet.",
            element: ".card-project",
            position: "bottom",
          },
          {
            title: "Factures",
            text: "Créer, visualiser, modifier ou supprimer vos factures.",
            element: ".card-invoice",
            position: "top",
          },
        ];
      }
    }
    if (pathName === "/calendar") {
      steps = [
        {
          title: "Calendrier",
          text: "Sur cette page, vous pouvez voir les événements à venir.",
          element: ".card-calendar",
          position: "bottom",
        },
        {
          title: "Calendrier",
          text: "Vous pouvez ajouter un événement ou filtrer le calendrier dans ce menu.",
          element: ".card-menu-calendar",
          position: "bottom",
        },
        {
          title: "Calendrier",
          text: "Vous pouvez changer la date en déplaçant l'événement dans le calendrier ou créer un nouvel événement en cliquant sur une date.",
          element: ".fc-daygrid-day",
          position: "bottom",
        },
      ];
    }
    if (pathName.includes("/todo")) {
      steps = [
        {
          title: "Tâches",
          text: "Sur cette page, vous pouvez voir vos tâches à faire.",
          element: ".card-task",
          position: "bottom",
        },
        {
          title: "Tâches",
          text: "Vous pouvez ajouter une tâche ou filtrer les tâches dans ce menu.",
          element: ".card-menu-todo",
          position: "bottom",
        },
      ];
    }
    if (pathName === "/chat") {
      steps = [
        {
          title: "Chat",
          text: "Sur cette page, vous pouvez discuter avec vos collègues.",
          element: ".chat-application",
          position: "bottom",
        },
      ];
    }
    if (pathName === "/flowchart") {
      steps = [
        {
          title: "Organigramme",
          text: "Sur cette page, vous pouvez voir l'ensemble des clients et projets avec leurs participants.",
          element: ".chart-container",
          position: "bottom",
        },
      ];
    }
    if (pathName === "/profile") {
      steps = [
        {
          title: "Profil",
          text: "Sur cette page, vous pouvez modifier vos informations personnelles.",
          element: ".card-edit",
          position: "bottom",
        },
        {
          title: "Profil",
          text: "Vous pouvez modifier votre avatar.",
          element: ".card-avatar",
          position: "bottom",
        },
        {
          title: "Profil",
          text: "Vous pouvez modifier votre mot de passe.",
          element: ".card-password",
          position: "bottom",
        },
      ];
    }

    const shepherdSteps = steps.map((step, index) => {
      const buttons = this.generateButtons(index, steps.length, step.buttons);

      return {
        title: step.title,
        text: step.text,
        attachTo: {
          element: step.element,
          on: step.position,
        },
        cancelIcon: {
          enabled: true,
        },
        buttons,
        useModalOverlay: true,
      };
    });

    this.shepherdService.modal = true;
    this.shepherdService.addSteps(shepherdSteps);
  }

  private generateButtons(
    currentIndex: number,
    totalSteps: number,
    customButtons?: StepButton[]
  ): StepButton[] {
    if (customButtons) {
      return customButtons;
    }

    const buttons: StepButton[] = [];

    if (totalSteps === 1) {
      buttons.push(this.defaultCancelBtn, {
        ...this.defaultNextBtn,
        text: "Terminer",
      });
      return buttons;
    } else {
      if (currentIndex === 0) {
        buttons.push(this.defaultCancelBtn, this.defaultNextBtn);
      } else if (currentIndex === totalSteps - 1) {
        buttons.push(this.defaultBackBtn, {
          ...this.defaultNextBtn,
          text: "Terminer",
        });
      } else {
        buttons.push(
          this.defaultCancelBtn,
          this.defaultBackBtn,
          this.defaultNextBtn
        );
      }
    }

    return buttons;
  }

  startTutorial(): void {
    this.shepherdService?.start();
  }
}
