import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { Todo } from "./todo.model";
import { environment } from "environments/environment";
import { TODO_UPDATED_SUBSCRIPTION } from "app/apollo/requests/subscriptions";
import { Apollo } from "apollo-angular";
import { NotificationsService } from "app/layout/components/navbar/navbar-notification/notifications.service";
import { User } from "app/auth/models";
import { takeUntil } from "rxjs/operators";

@Injectable()
export class TodoService implements Resolve<any>, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public todos: Todo[];
  public assignee;
  public filters;
  public tags;
  public tempTodos: Todo[];
  public currentTodo: Todo;
  public sortParamRef = "order";

  public onTodoDataChange: BehaviorSubject<any>;
  public onCurrentTodoChange: BehaviorSubject<any>;
  public onAssigneeChange: BehaviorSubject<any>;
  public onFilterChange: BehaviorSubject<any>;
  public onTagChange: BehaviorSubject<any>;
  public onSearchQueryChange: BehaviorSubject<any>;
  public onFiltersChange: BehaviorSubject<any>;
  public onTagsChange: BehaviorSubject<any>;

  user: User;

  private routeParams: any;
  private sortTodoRef = (key) => (a, b) => {
    let fieldA;
    let fieldB;

    if (key === "dueDate") {
      fieldA = new Date(a[key]);
      fieldB = new Date(b[key]);
    } else if (key === "assignee") {
      fieldA = a.assignee ? a.assignee.fullname : null;
      fieldB = b.assignee ? b.assignee.fullname : null;
    } else {
      fieldA = a[key];
      fieldB = b[key];
    }

    let comparison = 0;

    if (fieldA === fieldB) {
      comparison = 0;
    } else if (fieldA === null) {
      comparison = 1;
    } else if (fieldB === null) {
      comparison = -1;
    } else if (fieldA > fieldB) {
      comparison = 1;
    } else if (fieldA < fieldB) {
      comparison = -1;
    }

    return comparison;
  };

  constructor(
    private _httpClient: HttpClient,
    private _apollo: Apollo,
    private _notificationService: NotificationsService
  ) {
    this.onTodoDataChange = new BehaviorSubject([]);
    this.onCurrentTodoChange = new BehaviorSubject({});
    this.onAssigneeChange = new BehaviorSubject({});
    this.onFilterChange = new BehaviorSubject({});
    this.onTagChange = new BehaviorSubject({});
    this.onSearchQueryChange = new BehaviorSubject({});
    this.onFiltersChange = new BehaviorSubject({});
    this.onTagsChange = new BehaviorSubject({});
    this.user = JSON.parse(localStorage.getItem("currentUser"));
  }

  markEventsAsSeen(state) {
    this._notificationService.onNotificationsChange.subscribe((data) => {
      if (
        data?.some(
          (notification) => notification?.type?.toLowerCase() === "task"
        ) &&
        this.user?.id &&
        state.url == "/todo/all"
      ) {
        this._notificationService.markTodosAsSeen(this.user?.id);
      }
    });
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;
    this.markEventsAsSeen(state);
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getTodosList(),
        this.getFilters(),
        this.getTags(),
        this.getAssignee(),
      ]).then(() => {
        resolve();
      }, reject);
    });
  }

  getTodosList(): Promise<any[]> {
    if (this.routeParams?.filter) {
      return this.getTodosByFilter(this.routeParams?.filter);
    }

    if (this.routeParams?.tag) {
      return this.getTodosByTag(this.routeParams?.tag);
    }
  }

  getFilters() {
    return new Promise<void>((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/api/Todo/GetFilters`)
        .subscribe((filters: any) => {
          this.filters = filters;
          this.onFiltersChange.next(this.filters);
          resolve();
        }, reject);
    });
  }

  getTags() {
    return new Promise<void>((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/api/Todo/GetTags`)
        .subscribe((tags: any) => {
          this.tags = tags;
          this.onTagsChange.next(this.tags);
          resolve();
        }, reject);
    });
  }

  getTodosByFilter(filterHandle: string, tag: string = ""): Promise<any[]> {
    let params = new HttpParams();

    switch (filterHandle) {
      case "all":
        params = params.set("filter", "all");
        break;
      case "deleted":
        params = params.set("filter", "deleted");
        break;
      default:
        params = params.set("filter", filterHandle);
        params = params.set("notDeleted", "true");
        break;
    }

    params = params.set("tag", tag);
    return this._httpClient
      .get<any[]>(`${environment.apiUrl}/api/Todo/GetTodos`, { params })
      .toPromise()
      .then((todos) => {
        this.todos = todos;
        this.tempTodos = todos;
        this.onTodoDataChange.next(this.todos);
        this.sortTodos(this.sortParamRef);
        return this.todos;
      });
  }

  getTodosByTag(tagHandel, filter: string = ""): Promise<any[]> {
    let params = new HttpParams();
    params = params.set("tag", tagHandel);
    params = params.set("filter", filter);
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/api/Todo/GetTodos`, { params })
        .subscribe((todos: any) => {
          this.todos = todos;
          this.tempTodos = todos;
          this.onTodoDataChange.next(this.todos);
          this.sortTodos(this.sortParamRef);
          resolve(this.todos);
        }, reject);
    });
  }

  getAssignee(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.apiUrl}/api/Todo/GetAssignees`)
        .subscribe((assignee: any) => {
          this.assignee = assignee;
          this.onAssigneeChange.next(this.assignee);
          resolve(this.todos);
        }, reject);
    });
  }

  getTodosBySearch(query) {
    const filteredTodos = this.tempTodos.filter((todo) => {
      return todo.title.toLowerCase().includes(query.toLowerCase());
    });
    this.todos = filteredTodos;
    this.onTodoDataChange.next(this.todos);
    this.sortTodos(this.sortParamRef);
  }

  createNewTodo() {
    this.currentTodo = new Todo();
    this.onCurrentTodoChange.next(this.currentTodo);
  }

  setCurrentTodo(id) {
    this.currentTodo = this.todos.find((todo) => {
      return todo.id === id;
    });
    this.onCurrentTodoChange.next(this.currentTodo);
  }

  updateCurrentTodo(todo: Todo, userId: number) {
    if (todo.id > 0) {
      this.currentTodo = todo;
      this.onCurrentTodoChange.next(this.currentTodo);
      this.postTodo(this.routeParams, userId);
    } else {
      this.currentTodo = todo;
      this.onCurrentTodoChange.next(this.currentTodo);
      this.postNewTodo(this.routeParams, userId);
    }
  }

  deleteTodoPermanent() {
    let routeParams = this.routeParams;
    let params = new HttpParams();

    switch (routeParams.filter) {
      case "all":
        params = params.set("filter", "all");
        break;
      case "deleted":
        params = params.set("filter", "deleted");
        break;
      default:
        params = params.set("filter", routeParams.filter);
        params = params.set("notDeleted", "true");
        break;
    }
    if (routeParams.tag?.length > 0)
      params = params.set("tag", routeParams.tag ?? "");

    this._httpClient
      .delete(
        `${environment.apiUrl}/api/Todo/DeleteTodo/${this.currentTodo.id}`,
        {
          params,
        }
      )
      .subscribe();
  }

  postTodo(routeParams: any, userId: number) {
    let params = new HttpParams();

    switch (routeParams.filter) {
      case "all":
        params = params.set("filter", "all");
        break;
      case "deleted":
        params = params.set("filter", "deleted");
        break;
      default:
        params = params.set("filter", routeParams.filter);
        params = params.set("notDeleted", "true");
        break;
    }
    if (routeParams.tag?.length > 0)
      params = params.set("tag", routeParams.tag ?? "");
    this.currentTodo.creatorId = userId;
    this._httpClient
      .patch(`${environment.apiUrl}/api/Todo/UpdateTodo`, this.currentTodo, {
        params,
      })
      .subscribe();
  }

  postNewTodo(routeParams, userId: number) {
    let params = new HttpParams();

    switch (routeParams.filter) {
      case "all":
        params = params.set("filter", "all");
        break;
      case "deleted":
        params = params.set("filter", "deleted");
        break;
      default:
        params = params.set("filter", routeParams.filter);
        params = params.set("notDeleted", "true");
        break;
    }

    if (routeParams.tag?.length > 0)
      params = params.set("tag", routeParams.tag ?? "");

    this.currentTodo.creatorId = userId;
    this._httpClient
      .post(`${environment.apiUrl}/api/Todo/CreateTodo`, this.currentTodo, {
        params,
      })
      .subscribe();
  }

  sortTodos(sortByParam) {
    this.sortParamRef = sortByParam;
    let sortDesc = true;

    const sortBy = (() => {
      if (sortByParam === "title-asc") {
        sortDesc = false;
        return "title";
      }
      if (sortByParam === "title-desc") return "title";
      if (sortByParam === "assignee") {
        sortDesc = false;
        return "assignee";
      }
      if (sortByParam === "due-date") {
        sortDesc = false;
        return "dueDate";
      }
      return null;
    })();

    if (sortByParam !== "order") {
      this.todos = this.todos.sort(this.sortTodoRef(sortBy));
      if (sortDesc) this.todos.reverse();
      this.onTodoDataChange.next(this.todos);
    } else {
      this.onTodoDataChange.next(this.todos);
    }
  }

  subscribeToTodoUpdated(): void {
    this._apollo
      .subscribe({
        query: TODO_UPDATED_SUBSCRIPTION,
        variables: { userId: this.user?.id },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }: any) => {
        this.todos = data?.onTodoUpdated;
        this.tempTodos = data?.onTodoUpdated;
        this.sortTodos(this.sortParamRef);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
